<template>
  <v-card flat class="rounded-20 ma-4" height="70vh">
    <v-card-text>
      <v-row class="my-10">
        <v-col cols="3" />
        <v-col cols="3" class="d-flex justify-center">
          <v-hover v-slot="{ hover }">
            <div>
              <v-card
                flat
                height="225"
                width="225"
                :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
                @click="exportOrOrder = 'export'"
              >
                <v-card-text class="px-1">
                  <v-row no-gutters class="d-flex justify-center align-center">
                    <v-col
                      cols="auto"
                      class="d-flex align-center justify-center"
                    >
                      <ImplantExport
                        :color="hover ? 'white' : iconColor"
                        style="height: 40px; width: 40px"
                        class="ma-1"
                      />
                    </v-col>
                    <v-col>
                      <span
                        class="title-font d-flex justify-center align-center"
                        :style="{
                          wordBreak: 'break-word',
                          color: hover ? 'white' : '#147BD1'
                        }"
                      >
                        EXPORT ABUTMENT POSITIONS
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-img
                  v-if="!hover"
                  contain
                  max-height="100"
                  :src="require('@/assets/abutment-export.svg')"
                  position="center center"
                />
                <v-img
                  v-if="hover"
                  contain
                  max-height="100"
                  :src="require('@/assets/abutment-export-light.svg')"
                  position="center center"
                />
                <v-card-actions> </v-card-actions>
              </v-card>
              <v-card flat max-width="225" v-if="hover">
                <v-card-text>
                  Export implant positions:
                  <ul>
                    <li>Choose an OEM Scan Body and connection geometry.</li>
                    <li>Your choice of direct to MUA or Ti Base.</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </v-col>
        <v-col cols="3" class="d-flex justify-center">
          <v-hover v-slot="{ hover }">
            <div>
              <v-card
                flat
                height="225"
                width="225"
                :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
                @click="exportOrOrder = 'order'"
              >
                <v-card-text class="px-1">
                  <v-row no-gutters class="d-flex justify-center align-center">
                    <v-col
                      cols="auto"
                      class="d-flex justify-center align-center"
                    >
                      <OrderIcon
                        :color="hover ? 'white' : iconColor"
                        style="height: 40px; width: 40px"
                        class="ma-1"
                      />
                    </v-col>
                    <v-col>
                      <span
                        class="title-font d-flex justify-center align-center"
                        :style="{
                          wordBreak: 'break-word',
                          color: hover ? 'white' : '#147BD1'
                        }"
                      >
                        ORDER FROM OSTEON
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-img
                  v-if="hover"
                  contain
                  max-height="100"
                  :src="require('@/assets/order-light.svg')"
                  position="center center"
                />
                <v-img
                  v-if="!hover"
                  contain
                  max-height="100"
                  :src="require('@/assets/order.svg')"
                  position="center center"
                />
              </v-card>
              <v-card flat max-width="225" v-if="hover">
                <v-card-text>
                  Choose your design option:
                  <ul>
                    <li>Design Only File (Per Arch) Milled Bar</li>
                    <li>Fully Finished Prosthesis</li>
                  </ul>
                </v-card-text>
              </v-card>
            </div>
          </v-hover>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImplantExport from "@/components/shared/icons/ImplantExport"
import OrderIcon from "@/components/shared/icons/OrderIcon"

export default {
  name: "ChooseExportOrOrder",

  props: ["value"],

  components: {
    ImplantExport,
    OrderIcon
  },

  data() {
    return {
      iconColor: "#147BD1"
    }
  },

  computed: {
    exportOrOrder: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
