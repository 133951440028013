<template>
  <v-container fluid v-if="!isUnknown" style="position: relative">
    <!-- :height="height" -->
    <!-- :width="width" -->
    <ModelStl
      v-if="isSTLFile"
      :background-color="0xffffff"
      :lights="lights"
      :src="url"
      :cameraPosition="cameraPositions"
      @on-load="setLoaded"
    />
    <!-- :height="height" -->
    <!-- :width="width" -->
    <ModelPly
      v-if="isPLYFile"
      :background-color="0xffffff"
      :lights="lights"
      :src="url"
      @on-load="setLoaded"
    />
    <v-progress-circular
      v-if="!loaded"
      indeterminate
      :size="height / 2"
      style="
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      "
    />
  </v-container>
</template>

<script>
import { ModelPly, ModelStl } from "vue-3d-model"

export default {
  props: ["filename", "url", "height", "width", "cameraPositions"],

  components: {
    ModelPly,
    ModelStl
  },

  data() {
    return {
      loaded: false,
      lights: [
        {
          type: "HemisphereLight",
          position: { x: 0, y: 1, z: 0 },
          skyColor: 0xffffff,
          groundColor: 0x806060,
          intensity: 0.5
        },
        {
          type: "DirectionalLight",
          position: { x: 0, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: 0, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: 10, y: -10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        },
        {
          type: "DirectionalLight",
          position: { x: -10, y: 10, z: -10 },
          color: 0xffffff,
          intensity: 0.3
        }
      ]
    }
  },
  computed: {
    isSTLFile() {
      if (!this.filename) {
        return false
      }
      if (this.filename.toLowerCase().includes(".stl")) {
        return true
      }
      return false
    },

    isPLYFile() {
      if (!this.filename) {
        return false
      }
      if (this.filename.toLowerCase().includes(".ply")) {
        return true
      }
      return false
    },

    isUnknown() {
      return !this.isPLYFile && !this.isSTLFile
    }
  },

  methods: {
    setLoaded() {
      this.loaded = true
    }
  }
}
</script>
