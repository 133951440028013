<template>
  <v-card flat class="ma-4 rounded-20">
    <v-card-title> Existing Export Files </v-card-title>
    <v-card-text v-if="!historyExports.length">
      There are no Existing Export Files.
    </v-card-text>
    <v-simple-table v-if="historyExports.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Scan Body</th>
            <th>Healing Cap</th>
            <th class="text-right">Exported At</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="exportItem in historyExports"
            :key="exportItem.uid"
            style="cursor: pointer"
            :class="{ 'gradient-bg-2': exportItem.uid === newExportUid }"
          >
            <td>
              {{ libraryName(exportItem.scan_body_component_id) }}

              <v-btn
                icon
                @click="downloadFile(exportItem.scan_body_file)"
                class="ml-2"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </td>
            <td>
              {{ libraryName(exportItem.healing_cap_component_id) }}

              <v-btn
                icon
                @click="downloadFile(exportItem.healing_cap_file)"
                class="ml-2"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </td>
            <td class="text-right">{{ exportItem.created_at | luxon }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import { scanBodyLibraries } from "@/utils"

export default {
  name: "ExistingExports",

  props: ["value", "historyExports"],

  data() {
    return {
      scanBodyLibraries,
      timeout: null
    }
  },

  computed: {
    ...mapGetters(["exportComponents"]),

    newExportUid: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },

  watch: {
    newExportUid(val) {
      if (val)
        this.timeout = setTimeout(() => (this.newExportUid = null), 10000)
    }
  },

  methods: {
    libraryName(componentId) {
      return this.exportComponents.find((c) => c.id === parseInt(componentId))
        ?.name
    },

    downloadFile(url) {
      window.open(url, "_blank")
    }
  },

  unmounted() {
    clearTimeout(this.timeout)
  }
}
</script>
