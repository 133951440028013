<template>
  <v-card-text class="py-1">
    <v-row no-gutters class="mb-3">
      <v-col
        v-for="(_, index) in selectedGaugeLibraries"
        :key="index"
        align="center"
        class="my-1"
      >
        <v-badge
          :value="
            index === selectedScanGaugeIndex && selectedGaugeLibraries[index]
          "
          left
          dot
          color="primary"
        >
          <v-btn
            small
            elevation="0"
            :class="[
              { 'btn-primary': index === selectedScanGaugeIndex },
              { 'btn-success ': selectedGaugeLibraries[index] },
              'title-font',
              'rounded-10'
            ]"
            @click="$emit('select', index)"
          >
            {{ `Scan Gauge - ${index + 1}` }}
          </v-btn>
        </v-badge>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="center">
        <v-btn
          elevation="0"
          :class="[
            { 'rounded-10': true },
            { 'mb-2': true },
            { 'btn-success': readyToComplete }
          ]"
          @click="$emit('confirm')"
          :disabled="!readyToComplete"
        >
          <v-icon>mdi-check</v-icon>
          Confirm Association
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "ScanSelectConfirm",

  props: ["selectedGaugeLibraries", "selectedScanGaugeIndex", "loading"],

  computed: {
    readyToComplete() {
      if (this.loading) return false
      return this.selectedGaugeLibraries.every((x) => x)
    }
  }
}
</script>
