<template>
  <v-dialog v-model="dialog" max-width="700px" content-class="rounded-20">
    <v-card flat>
      <v-card-title class="off">
        <span class="text-h5">File Uploader</span>
      </v-card-title>

      <v-card-subtitle class="off">
        Select the file type and category you want to Upload
      </v-card-subtitle>

      <v-card-text class="py-5">
        <v-row>
          <v-col cols="3">
            <v-select
              v-model="fileType"
              :items="fileTypeItems"
              dense
              outlined
              hide-details
              :menu-props="{ rounded: '10' }"
              placeholder="File Type"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="fileCategory"
              :items="fileCategoryItems"
              dense
              outlined
              hide-details
              single-line
              :menu-props="{ rounded: '10' }"
              placeholder="File Category"
            >
              <template v-slot:item="{ item }">
                {{ item.title }}
                {{ item.subtitle }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.title }} {{ item.subtitle }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="fileUploads"
              dense
              outlined
              multiple
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              hide-details
              placeholder="Upload Files"
            />
          </v-col>
          <v-col v-if="fileUploads.length > 0" cols="12" align="end">
            <v-chip
              small
              v-for="(file, index) in fileUploads"
              :key="index"
              close
              class="ma-1 rounded-10"
              @click:close="removeFile(index)"
            >
              <v-icon small>mdi-paperclip</v-icon>
              <span class="text-truncate">
                {{ file.name }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="off">
        <v-spacer />
        <v-btn
          class="btn-error"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn class="btn-primary" :loading="loading" @click="upload()">
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddFileModal",

  props: ["value", "categories", "loading"],

  watch: {
    dialog(value) {
      if (!value) {
        this.fileType = ""
        this.fileCategory = ""
        this.fileUploads = []
      }
    },

    loading(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.dialog = false
      }
    }
  },

  data() {
    return {
      fileUploads: [],
      fileType: "",
      fileCategory: "",
      fileTypeItems: [
        {
          text: "Scan",
          value: "scan"
        },
        {
          text: "Photo",
          value: "photo"
        },
        {
          text: "Other",
          value: "other"
        }
      ]
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    fileCategoryItems() {
      return this.categories
    }
  },

  methods: {
    async upload() {
      const formData = new FormData()
      formData.append("file_type", this.fileType)
      formData.append("file_category", this.fileCategory)
      this.fileUploads.forEach((file) => {
        formData.append("files", file, file.name)
      })
      this.$emit("upload", formData)
    },

    removeFile(index) {
      this.fileUploads.splice(index, 1)
    }
  }
}
</script>
