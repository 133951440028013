<template>
  <v-card width="70vw" class="rounded-20 card-bg pa-2">
    <v-card-title
      class="title-font font-weight-medium white rounded-20 mb-5 py-3"
    >
      {{
        !validateScansResponse
          ? "Select Scan Gauges"
          : validationSuccess
          ? "Scan Analysis Success"
          : "Scan Analysis Failure"
      }}
      <span v-if="retryCount && validationSuccess === false">
        &nbsp;- Attempt {{ retryCount + 1 }}
      </span>
      <v-spacer />
      <div
        v-if="uploadedScans.length === 3 && validationSuccess === false"
        class="mr-3"
      >
        &nbsp;<v-icon color="error" class="mr-1">mdi-alert-circle</v-icon>
        Maximum retry count reached.
      </div>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="height: 500px" class="white rounded-20 py-0 px-3">
      <v-row class="fill-height" v-show="!validateScansResponse">
        <v-col cols="6" class="fill-height">
          <v-card
            class="fill-width fill-height elevation-0 view-background rounded-10"
          >
            <div ref="scan1View"></div>
          </v-card>
        </v-col>

        <v-col cols="6" class="fill-height">
          <v-card
            class="fill-width fill-height elevation-0 view-background rounded-10"
          >
            <div ref="scan2View"></div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fill-height" v-show="validateScansResponse">
        <v-col cols="6" class="fill-width fill-height">
          <v-fade-transition>
            <v-card
              class="fill-width fill-height elevation-0 view-background rounded-10"
            >
              <div ref="scanValidationView"></div>
            </v-card>
          </v-fade-transition>
        </v-col>
        <v-col cols="6" class="fill-width fill-height">
          <v-card class="fill-width fill-height elevation-0">
            <v-card-text>
              <h1
                :class="[
                  'py-5',
                  'mb-4',
                  'title-font',
                  validationSuccess ? 'success--text' : 'error--text'
                ]"
                style="font-size: 80px"
              >
                {{ validationSuccess ? "PASS" : "FAIL" }}
              </h1>
              <v-form>
                <v-btn
                  @click="onScanColorsButtonClick"
                  class="rounded-10 elevation-0"
                  :color="validationSuccess ? 'success' : 'error'"
                >
                  {{ toggleScanColorsButtonLabel }}
                </v-btn>
                <v-checkbox v-model="showScanTissue" label="Show Scan Tissue" />
              </v-form>
              <HeatmapScale v-if="showHeatmapColors" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="white rounded-20 mt-2 py-3">
      <template v-if="!validateScansResponse">
        <v-btn
          class="btn-primary mr-4"
          @click="select"
          :disabled="loading"
          :loading="loading"
        >
          Confirm Selections
        </v-btn>
        <v-btn
          class="rounded-10 elevation-0"
          @click="clearSelections"
          :disabled="loading"
        >
          Clear All Selections
        </v-btn>
        <v-spacer />
        <div v-if="retrying && faceCount">
          Expected Gauge Selection Count: <strong> {{ faceCount }} </strong>
          <v-btn small @click="restart" class="ml-3 elevation-0 rounded-10">
            Restart
          </v-btn>
        </div>
      </template>
      <template v-if="validateScansResponse">
        <v-btn
          :class="[
            'mr-2 rounded-10 elevation-0 px-5',
            validationSuccess ? 'success' : 'error'
          ]"
          @click="$emit('close')"
        >
          {{ validationSuccess ? "Finish" : "Close" }}
        </v-btn>
        <template v-if="!validationSuccess">
          <v-btn
            class="btn-primary mr-2"
            :disabled="!retryPermitted"
            @click="onRetry"
          >
            Retry Analysis
          </v-btn>
          <v-btn class="rounded-10 elevation-0" @click="restart">
            Restart Session
          </v-btn>
        </template>
      </template>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.view-background {
  background: linear-gradient(
    to right bottom,
    #cbd7df 0%,
    #eeefee 67%,
    rgba(255, 249, 234, 0.25)
  );
}
</style>

<script>
import { mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"
import HeatmapScale from "@/components/shared/HeatmapScale.vue"

export default {
  scan1View: null,
  scan2View: null,
  scanValidationView: null,

  props: [
    "value",
    "retrying",
    "ncSessionId",
    "addScansResponse",
    "uploadedScans",
    "validationSuccess",
    "validateScansDialog",
    "retryCount",
    "immediate"
  ],

  components: {
    HeatmapScale
  },

  data() {
    return {
      loading: false,
      showScanTissue: true,
      showHeatmapColors: false,
      selectGaugesResponse: null,
      faceCount: null,
      scanViewReloads: 0
    }
  },

  computed: {
    ...mapGetters(["token"]),

    validateScansResponse: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit("input", value)
      }
    },

    toggleScanColorsButtonLabel() {
      return this.showHeatmapColors
        ? "Show Score Colors"
        : "Show Heatmap Colors"
    },

    retryPermitted() {
      return this.uploadedScans.length < 3
    }
  },

  watch: {
    retrying(value) {
      if (value) {
        this.scan1View.clearVisualisation()
        this.scan2View.clearVisualisation()
        this.scanValidationView.clearVisualisation()
        this.selectGaugesResponse = null
      }
    },

    ncSessionId(value) {
      if (value === null) this.restart()
    },

    showScanTissue(value) {
      if (this.scanValidationView && this.scanValidationView.visualisation) {
        this.scanValidationView.visualisation.setScanTissueVisible(value)
      }
    },

    validateScansDialog(value) {
      if (value && this.retrying && this.scanViewReloads < this.retryCount) {
        this.setScanVisualisations()
        this.scanViewReloads++
      }
    }
  },

  mounted() {
    this.scan1View = NCFrontend.createView("scan1View", this.$refs.scan1View)
    this.scan2View = NCFrontend.createView("scan2View", this.$refs.scan2View)
    this.scanValidationView = NCFrontend.createView(
      "scanValidationView",
      this.$refs.scanValidationView
    )
    NCFrontend.add(this.scan1View, this.scan2View, this.scanValidationView)
    this.setScanVisualisations()
  },

  unmounted() {
    NCFrontend.remove(this.scan1View, this.scan2View, this.scanValidationView)
  },

  methods: {
    setScanVisualisations() {
      this.scan1View.setVisualisation(
        new NCFrontend.Visualisations.ScanGaugeSelect(this.scan1View.element, {
          mesh: this.addScansResponse.scan1.mesh,
          detectedPlanes: this.addScansResponse.scan1.detectedPlanes
        })
      )
      this.scan2View.setVisualisation(
        new NCFrontend.Visualisations.ScanGaugeSelect(this.scan2View.element, {
          mesh: this.addScansResponse.scan2.mesh,
          detectedPlanes: this.addScansResponse.scan2.detectedPlanes
        })
      )
    },

    async select() {
      const scan1SelectedPlaneIndices =
        this.scan1View.visualisation.selectedPlaneIndices
      const scan2SelectedPlaneIndices =
        this.scan2View.visualisation.selectedPlaneIndices
      if (
        scan1SelectedPlaneIndices.length === 0 ||
        scan2SelectedPlaneIndices.length === 0
      ) {
        this.$emit("error", "No Scan Gauges have been selected.")
        return
      }
      if (
        scan1SelectedPlaneIndices.length !== scan2SelectedPlaneIndices.length
      ) {
        this.$emit("error", "Mismatching number of Scan Gauges selected.")
        return
      }
      if (
        this.faceCount !== null &&
        scan1SelectedPlaneIndices.length !== this.faceCount
      ) {
        this.$emit(
          "error",
          `Selection Mismatch: Must select ${this.faceCount} Scan Gauges`
        )
        return
      }

      try {
        this.loading = true
        this.scan1View.visualisation.stopSelection()
        this.scan2View.visualisation.stopSelection()
        const selectGaugesResponse =
          await NCFrontend.ApiClient.sessionSelectGauges(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization,
            {
              sessionId: this.ncSessionId,
              scan1Id: this.addScansResponse.scan1.id,
              scan2Id: this.addScansResponse.scan2.id,
              scan1SelectedPlaneIndices,
              scan2SelectedPlaneIndices
            }
          )
        await Promise.all([
          this.scan1View.visualisation.showSelectedGauges(
            selectGaugesResponse.scan1Gauges
          ),
          this.scan2View.visualisation.showSelectedGauges(
            selectGaugesResponse.scan2Gauges
          )
        ])
        this.selectGaugesResponse = selectGaugesResponse
      } catch (err) {
        this.setErrorMessage("An internal error occurred.")
        console.error(err)
      } finally {
        this.loading = false
      }
      this.validateScans()
    },

    async validateScans() {
      try {
        this.loading = true
        const validateScansResponse =
          await NCFrontend.ApiClient.sessionValidateScans(
            `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
            this.$axios.defaults.headers.Authorization,
            {
              sessionId: this.ncSessionId,
              scan1Id: this.addScansResponse.scan1.id,
              scan2Id: this.addScansResponse.scan2.id,
              retrying: this.retrying
            }
          )
        this.validateScansResponse = validateScansResponse
        if (this.faceCount === null)
          this.faceCount =
            this.scan1View.visualisation.selectedPlaneIndices.length
        this.$emit("retrying", false)
        this.$nextTick(() => {
          this.scanValidationView.setVisualisation(
            new NCFrontend.Visualisations.ScanValidation(
              this.$refs.scanValidationView,
              {
                modelScan: this.validateScansResponse.modelScan,
                validation: this.validateScansResponse.validation,
                immediateCase: this.immediate
              }
            )
          )
        })
      } catch (err) {
        this.$emit("error", "An internal error occurred.")
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    onScanColorsButtonClick() {
      if (this.showHeatmapColors) {
        this.scanValidationView.visualisation.showScoreColors()
        this.showHeatmapColors = false
      } else {
        this.scanValidationView.visualisation.showHeatmapColors()
        this.showHeatmapColors = true
      }
    },

    reselect() {
      this.scanValidationView.clearVisualisation()
      this.validateScansResponse = null
      this.selectGaugesResponse = null
      this.scan1View.visualisation.clearSelections()
      this.scan2View.visualisation.clearSelections()
      this.scan1View.visualisation.startSelection()
      this.scan2View.visualisation.startSelection()
    },

    clearSelections() {
      this.scan1View.visualisation.clearSelections()
      this.scan2View.visualisation.clearSelections()
    },

    onRetry() {
      this.scan1View.clearVisualisation()
      this.scan2View.clearVisualisation()
      this.scanValidationView.clearVisualisation()
      this.selectGaugesResponse = null
      this.$emit("retrying", true)
      this.$emit("close")
    },

    restart() {
      this.reselect()
      this.selectGaugesResponse = null
      this.showScanTissue = true
      this.showHeatmapColors = false
      this.scanViewReloads = 0
      this.faceCount = null
      if (this.ncSessionId) this.$emit("restart")
    }
  }
}
</script>
