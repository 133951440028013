<template>
  <v-container fluid fill-height class="pt-0">
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      stateless
      absolute
      :width="drawerWidth"
      class="card-bg"
    >
      <OrderView v-if="!newOrder && uid" :uid="uid" />
      <NewOrder v-if="newOrder" @submission="handleNewOrder" />
    </v-navigation-drawer>
    <v-row class="fill-height">
      <v-col cols="2" class="py-0">
        <v-card flat class="card-bg rounded-20">
          <v-card-text>
            <v-list dark dense class="transparent pt-0">
              <!-- :disabled="!scanGaugeKits?.length" -->
              <v-list-item
                class="secondary"
                :to="
                  scanGaugeKits?.length
                    ? { name: 'caseView', params: { uid: 'new' } }
                    : { name: 'scanGaugeKitsPage' }
                "
              >
                <v-list-item-icon class="mr-4">
                  <v-icon v-if="scanGaugeKits?.length"> mdi-plus </v-icon>
                  <v-icon v-if="!scanGaugeKits?.length">
                    mdi-alert-circle-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="title-font font-weight-medium">
                    {{
                      scanGaugeKits?.length ? "CREATE NEW ORDER" : "ORDER A KIT"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <InboxActions />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" class="py-0 pl-0">
        <InboxItems
          :inboxItems="actionedFilter"
          :loading="loading"
          :filter="filter"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="welcome" width="600px" content-class="rounded-20">
      <v-card @click="welcome = false" class="pa-3">
        <v-card-title>
          <h2 class="title-h2 primary--text">Congratulations!</h2>
        </v-card-title>
        <v-card-text>
          <strong>
            Welcome to Nexus Connect Lab, Your Subscription is Now Ready!
          </strong>
        </v-card-text>
        <v-responsive class="mx-5 mb-3">
          <iframe
            width="100%"
            height="310"
            src="https://www.youtube.com/embed/yWKhXTYWVYY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            class="rounded-10"
          ></iframe>
        </v-responsive>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import InboxActions from "./InboxPage/InboxActions"
import InboxItems from "./InboxPage/InboxItems"
import OrderView from "./InboxPage/OrderView"
import NewOrder from "./InboxPage/NewOrder"

export default {
  name: "InboxPage",

  props: ["filter", "uid"],

  components: {
    InboxActions,
    InboxItems,
    OrderView,
    NewOrder
  },

  data() {
    return {
      welcome: this.$route.params.welcome || false,
      drawer: false,
      loading: false,
      selectedCase: null
    }
  },

  watch: {
    inboxItems(val) {
      if (val) this.loading = false
    },
    drawer(value) {
      if (!value) {
        return
      }
      this.$vuetify.goTo(0, { duration: 0 })
    },

    $route(value) {
      if (value.name !== "caseView") {
        this.drawer = false
        return
      }
      this.drawer = true
    }
  },

  computed: {
    ...mapGetters(["inboxItems", "scanGaugeKits"]),

    effectiveFilter() {
      return this.filter || "inbox"
    },

    actionedFilter() {
      if (this.inboxItems === null) return []
      if (this.effectiveFilter === "all") return this.inboxItems
      return this.inboxItems.filter((item) =>
        item.statuses.includes(this.effectiveFilter)
      )
    },

    drawerWidth() {
      if (this.newOrder) {
        return "50%"
      }
      return "90%"
    },

    newOrder() {
      return this.$route.params.uid === "new"
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async fetchOrders() {
      this.triggerReload("inboxItems")
    },

    async handleNewOrder() {
      this.triggerReload("inboxItems")
      this.drawer = false
    },

    sendConfetti() {
      this.$confetti.start()
      setTimeout(() => this.$confetti.stop(), 2000)
    }
  },

  async mounted() {
    if (this.$route.params.welcome) {
      this.sendConfetti()
      this.triggerReload("all")
    }
    if (this.$route.meta.caseView) this.drawer = true
    if (this.inboxItems === null) this.loading = true
    this.fetchOrders()
  }
}
</script>
