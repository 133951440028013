<template>
  <v-card flat class="rounded-20 ma-4">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <ExportAbutment
            @export="updateHistory"
            @cancelled="$emit('cancelled')"
          />
        </v-col>
        <v-col cols="6">
          <ExistingExports
            v-model="newExportUid"
            :historyExports="historyExports"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ExportAbutment from "./ExportComponent/ExportAbutment.vue"
import ExistingExports from "./ExportComponent/ExistingExports.vue"

export default {
  name: "ExportComponent",

  data() {
    return {
      historyExports: [],
      newExportUid: null,
      loading: false
    }
  },

  components: {
    ExportAbutment,
    ExistingExports
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  },

  methods: {
    async fetchHistoryExports() {
      this.loading = true
      this.historyExports = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      this.historyExports.sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      )
      this.loading = false
    },

    updateHistory(historyExports, newExportUid) {
      this.historyExports = historyExports
      this.newExportUid = newExportUid
    }
  },

  mounted() {
    this.fetchHistoryExports()
  }
}
</script>
