<template>
  <div>
    <v-card v-if="workflowStep < 3" class="rounded-20 ma-4" flat>
      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="32"
              class="ml-5"
            />
          </v-card-title>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-card-text class="fill-width pa-0 d-flex justify-center">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <div v-if="workflowStep === 1 && this.ready">
        <v-card-text class="black--text py-0">
          <v-stepper vertical class="elevation-0 py-0" v-model="currentStep">
            <v-stepper-step
              step="1"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Export Abutment Position
            </v-stepper-step>
            <v-stepper-content step="1">
              <p>
                In order to order a milled bar you will need to supply us with a
                tooth design.
              </p>
              <p>
                You have yet to export abutment positions for this case. Please
                continue to export abutment positions.
              </p>
              <v-btn class="btn-primary" @click="$emit('toExport')">
                Export Abutment Position
              </v-btn>
            </v-stepper-content>
            <v-stepper-step
              step="2"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Complete Tooth Design
            </v-stepper-step>
            <v-stepper-content step="2" />
            <v-stepper-step step="3"> Order Milled Bar </v-stepper-step>
            <v-stepper-content step="3">
              <v-btn
                class="btn-success white--text my-4 mx-4"
                @click="handleContinue"
              >
                Order Bar Now
                <v-icon class="ml-3" size="40"> $milledbarDark </v-icon>
              </v-btn>
            </v-stepper-content>
            <v-stepper-step step="4"> Approve Bar Design </v-stepper-step>
            <v-stepper-content step="4" />
            <v-stepper-step step="5"> Bar Production </v-stepper-step>
            <v-stepper-content step="5" />
            <v-stepper-step step="6"> Bar Delivery </v-stepper-step>
            <v-stepper-content step="6" />
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-error" @click="$emit('back')"> Back </v-btn>
        </v-card-actions>
      </div>
    </v-card>

    <v-form ref="orderForm">
      <v-row v-if="workflowStep == 2">
        <v-col cols="6">
          <v-card class="rounded-20 ml-4 my-n2" flat>
            <MultiUnitConfirmation
              v-model="localForwardedOrderForm.implants"
              :interactive="true"
            />
          </v-card>
          <SelectAbutmentExport
            v-model="localForwardedOrderForm.order_form.abutmentAnalogPositions"
          />
        </v-col>

        <v-col cols="6">
          <UploadProjectFiles
            @step="handleStepChange"
            v-model="files"
            :options="localForwardedOrderForm"
          />
          <SpecialRequests v-model="localForwardedOrderForm.specialRequests" />
          <SubmitOrCancel @submit="handleSubmission" @step="handleStepChange" />
        </v-col>
      </v-row>
    </v-form>

    <PurchaseDialog
      v-model="purchaseDialog"
      :item="lineItem"
      :acquisitionMethod="acquisitionMethod"
      :loading="loading"
      @confirm="handleConfirm"
    />

    <v-row v-if="workflowStep == 3">
      <v-col cols="6">
        <v-card class="rounded-20 fill-height ml-4" flat>
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
          </v-card-title>
          <v-card-text class="d-flex justify-center fill-width">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
              position="center right"
            />
          </v-card-text>
          <v-card-text class="black--text pt-0">
            Case Status:
            <strong v-if="workflowStep === 3" class="warning--text py-1">
              Under Review
            </strong>
          </v-card-text>
          <v-card-text class="black--text pt-0">
            Osteon Case Number: <strong> #18902 </strong>
          </v-card-text>
          <v-card-text v-if="workflowStep === 3" class="black--text py-1">
            Our design team will notify you if your uploaded files are missing
            information or incorrect.
          </v-card-text>
          <v-card-text
            v-if="workflowStep === 3 && Object.values(files).some((f) => !!f)"
          >
            <v-card-subtitle class="px-0">
              <v-icon>$fileupload</v-icon>
              Uploaded Files:
            </v-card-subtitle>
            <v-card outlined class="px-0 py-2 rounded-lg">
              <v-chip
                v-for="(file, index) in Object.values(files).filter((f) => !!f)"
                :key="index"
                class="py-0 mx-1"
              >
                {{ file?.filename || `${file}.ply` }}
              </v-chip>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    CONFIRMED MULTI UNIT ABUTMENTS:
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-for="(implant, index) in implantSet"
                    :key="index"
                    class="font-weight-bold black--text"
                  >
                    <span
                      v-for="(
                        item, index
                      ) in localForwardedOrderForm.implants.filter(
                        (item) => item.implant_platform.uid === implant
                      )"
                      :key="index"
                    >
                      <span v-if="index === 0"
                        >{{ item.implant_platform.name }}:
                      </span>
                      <span v-if="index !== 0">, </span>
                      {{ item.tooth }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    CHOSEN SCAN BODY LIBRARY:
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-bold black--text">
                    Straumann SRA
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <CaseChat v-if="workflowStep == 3" class="fill-height" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepCopy } from "@/utils"
import client from "@/lib/ApiClient"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation.vue"
import SelectAbutmentExport from "@/components/shared/SelectAbutmentExport.vue"
import UploadProjectFiles from "./MilledBarOrder/UploadProjectFiles.vue"
import SubmitOrCancel from "./MilledBarOrder/SubmitOrCancel.vue"
import SpecialRequests from "@/components/shared/SpecialRequests.vue"
import CaseChat from "@/components/shared/CaseChat.vue"

export default {
  name: "MilledBarOrder",

  components: {
    PurchaseDialog,
    MultiUnitConfirmation,
    SelectAbutmentExport,
    UploadProjectFiles,
    SubmitOrCancel,
    SpecialRequests,
    CaseChat
  },

  data() {
    return {
      workflowStep: 1,
      files: {
        toothDesign: null,
        tissueOfRestoringArch: null,
        antagonistDentition: null
      },
      loading: false,
      purchaseDialog: false,
      acquisitionStrategy: null,
      exportComplete: false,
      lineItem: {
        description: "Milled Bar",
        amount: "2 000",
        currency: "AUD",
        quantity: 1,
        product_code: "BAR",
        quota_code: "bars"
      },
      ready: false,
      localForwardedOrderForm: {}
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm"
    ]),

    toothPickerResponse() {
      return this.selectedOrder.responses.find(
        (item) => item.widget === "tooth_picker"
      ).value
    },

    implantSet() {
      return [
        ...new Set(
          this.forwardedOrderForm.implants.map(
            (item) => item.implant_platform.uid
          )
        )
      ]
    },

    currentStep() {
      return this.exportComplete ? 3 : 1
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    }
  },

  watch: {
    files: {
      handler(obj) {
        this.localForwardedOrderForm.uploadedFiles = Object.values(obj)
      },
      deep: true
    },
    localForwardedOrderForm: {
      async handler(value) {
        if (!value || !this.selectedForwardedOrder) return
        const response = await client.instance.orders.updateForwardedOrder(
          this.selectedOrder.uid,
          this.selectedForwardedOrder.uid,
          value
        )
        this.setSelectedForwardedOrder(response)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setCurrentQuotas",
      "triggerReload"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },
    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.loading = false
      return this.workflowStep++
    },

    async handleSubmission() {
      if (!this.$refs.orderForm.validate()) {
        return
      }
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.purchaseDialog = true
    },

    // checkTokenActivation(token) {
    //   if (!token) return false
    //   const activated = token.products.find(
    //     (p) => p.product_code === "BAR"
    //   ).activated

    //   return activated > 0 ? true : false
    // },

    async handleConfirm(paymentMethodId) {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        metadata: {
          forwarded_order_uid: this.selectedForwardedOrder.uid
        }
      }
      await client.gateway.billing.commitPurchase(purchaseForm)

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }
      this.triggerReload("forwardedOrder")
      this.loading = false
      this.purchaseDialog = false
      this.handleStepChange(3)
    },

    async fetchHistoryExports() {
      this.loading = true
      const exportHistory = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      this.exportComplete = !!exportHistory.length
      this.loading = false
      this.ready = true
    }
  },

  async mounted() {
    this.$set(this.localForwardedOrderForm, "uploadedFiles", [])
    this.fetchHistoryExports()
    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(3)
      } else {
        this.handleStepChange(2)
      }
    } else {
      this.$set(this.localForwardedOrderForm, "implants", [
        ...this.selectedOrder.responses.find(
          (item) => item.widget === "tooth_picker"
        ).value
      ])
    }
  },

  beforeMount() {
    this.localForwardedOrderForm = deepCopy(this.forwardedOrderForm)
  }
}
</script>
