<template>
  <v-card class="rounded-20 ma-4" flat>
    <v-row>
      <v-col cols="8">
        <v-card-text class="black--text">
          To continue with abutment export, confirm the choices below.
        </v-card-text>
        <v-card-text class="black--text">
          Choose the desired Scan Body to match your desired library for
          geometry and design purposes.
        </v-card-text>
        <v-select
          v-model="scanBodyComponentId"
          label="Scan Body Library for Export"
          :items="mappedScanBodies"
          outlined
          dense
          hide-details
          class="mx-4"
          :menu-props="{ rounded: '10' }"
        />
      </v-col>
      <v-col cols="4">
        <ModelViewer
          v-if="scanBodyMesh"
          :filename="scanBodyMesh.filename"
          :url="scanBodyMesh.url"
          :camera-positions="{ x: 0, y: -10, z: 10 }"
          :height="150"
          :width="125"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          v-model="healingCapComponentId"
          label="Healing Cap Library for Export"
          :items="mappedHealingCaps"
          outlined
          dense
          hide-details
          class="mx-4"
          :menu-props="{ rounded: '10' }"
        />
      </v-col>
      <v-col cols="4">
        <ModelViewer
          v-if="healingCapMesh"
          :filename="healingCapMesh.filename"
          :url="healingCapMesh.url"
          :camera-positions="{ x: 0, y: -10, z: 10 }"
          :height="150"
          :width="125"
        />
      </v-col>
    </v-row>
    <v-card-actions class="my-4 mx-2 pb-5">
      <v-progress-linear indeterminate v-if="loading" class="mr-5 mt-8" />
      <v-spacer />
      <v-btn class="btn-error" elevation="0" @click="cancelDownload">
        CANCEL
      </v-btn>
      <v-btn
        class="btn-primary white--text"
        :disabled="!scanBodyComponentId || !healingCapComponentId"
        @click="requestDownload"
      >
        DOWNLOAD
      </v-btn>
    </v-card-actions>

    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :acquisitionMethod="acquisitionMethod"
      :loading="loading"
      @confirm="consumeExport"
    />
  </v-card>
</template>

<script>
import { v4 as uuid } from "uuid"
import { mapActions, mapGetters } from "vuex"
import { delay } from "@/utils"
import client from "@/lib/ApiClient"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  name: "ExportAbutment",

  components: { PurchaseDialog, ModelViewer },

  data() {
    return {
      dialog: false,
      loading: false,
      scanBodyComponentId: null,
      healingCapComponentId: null,
      scanBodyMesh: null,
      healingCapMesh: null,
      // Perhaps move meshCache to store.
      // Perhaps fetch all meshes upfront?
      meshCache: [],
      acquisitionStrategy: null,
      exportUid: null,
      lineItem: {
        description: "Abutment Position Export",
        amount: "100",
        currency: "AUD",
        quantity: 1,
        product_code: "EXPORT",
        quota_code: "exports"
      }
    }
  },

  async mounted() {
    if (!this.exportComponents) this.fetchExportComponents()
  },

  computed: {
    ...mapGetters(["currentQuotas", "selectedOrder", "exportComponents"]),

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "EXPORT")
        .method
    },

    exportsAvailable() {
      const exports = this.currentQuotas.find((q) => q.quota_code === "exports")
      return exports.value
    },

    mappedScanBodies() {
      if (!this.exportComponents) return []
      return this.exportComponents
        .filter((c) => c.type === "Scan Body")
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          }
        })
    },

    mappedHealingCaps() {
      if (!this.exportComponents) return []
      return this.exportComponents
        .filter((c) => c.type === "Healing Cap")
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          }
        })
    }
  },

  watch: {
    scanBodyComponentId(val) {
      if (val) {
        this.fetchExportMesh("scanBody")
      }
    },
    healingCapComponentId(val) {
      if (val) {
        this.fetchExportMesh("healingCap")
      }
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas", "triggerReload", "setExportComponents"]),

    async fetchExportComponents() {
      this.loading = true
      this.setExportComponents(
        await client.gateway.components.fetchExportComponents()
      )
      this.loading = false
    },

    async fetchExportMesh(type) {
      const typeId = type + "ComponentId"
      const typeMesh = type + "Mesh"

      const cachedMesh = this.meshCache.find(
        (m) => m.component_id === this[typeId]
      )
      if (cachedMesh) return (this[typeMesh] = cachedMesh)

      this.loading = true
      this[typeMesh] = await client.gateway.components.fetchExportMesh(
        this[typeId]
      )
      this.meshCache.push(this[typeMesh])
      this.loading = false
    },

    async requestDownload() {
      if (!this.scanBodyComponentId || !this.healingCapComponentId) return
      this.loading = true

      if (this.selectedOrder.export_token_uid) {
        this.exportUid = uuid()
        await client.gateway.billing.consumeToken(
          this.selectedOrder.export_token_uid,
          {
            export_uid: this.exportUid,
            scan_body_id: this.scanBodyComponentId,
            healing_cap_id: this.healingCapComponentId
          }
        )
        await this.pollExport()
      } else {
        const payload = {
          items: [
            {
              product_code: "EXPORT",
              quantity: 1
            }
          ],
          payment_type: "stripe",
          order_uid: this.selectedOrder.uid
        }

        this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
          payload
        )

        this.dialog = true
      }

      this.loading = false
    },

    async refreshExports() {
      const historyExports = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      historyExports.sort((a, b) => b.created_at.localeCompare(a.created_at))
      this.$emit("export", historyExports, this.exportUid)

      this.exportUid = null
    },

    async pollExport() {
      const ready = await client.instance.exports.checkExport(
        this.selectedOrder.uid,
        this.exportUid
      )
      if (!ready) {
        await delay(1000)
        return this.pollExport()
      } else await this.refreshExports()
    },

    async consumeExport(paymentMethodId) {
      this.loading = true
      this.exportUid = uuid()

      const payload = {
        // Receive product code from gateway
        // quantity from gateway endpoint?
        // payment type from default payment method?
        items: [
          {
            product_code: "EXPORT",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        metadata: {
          export_uid: this.exportUid,
          scan_body_id: this.scanBodyComponentId,
          healing_cap_id: this.healingCapComponentId
        }
      }

      await client.gateway.billing.commitPurchase(payload)
      await this.pollExport()

      this.triggerReload("order")

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }

      this.dialog = false
      this.loading = false
    },

    cancelDownload() {
      this.$emit("cancelled")
    }
  }
}
</script>
