var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-20 ma-4",attrs:{"flat":"","height":"70vh"}},[_c('v-card-text',[_c('v-row',{staticClass:"my-10"},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:['rounded-10', 'blue-border', { 'btn-primary': hover }],attrs:{"flat":"","height":"225","width":"225"},on:{"click":function($event){_vm.exportOrOrder = 'export'}}},[_c('v-card-text',{staticClass:"px-1"},[_c('v-row',{staticClass:"d-flex justify-center align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"auto"}},[_c('ImplantExport',{staticClass:"ma-1",staticStyle:{"height":"40px","width":"40px"},attrs:{"color":hover ? 'white' : _vm.iconColor}})],1),_c('v-col',[_c('span',{staticClass:"title-font d-flex justify-center align-center",style:({
                        wordBreak: 'break-word',
                        color: hover ? 'white' : '#147BD1'
                      })},[_vm._v(" EXPORT ABUTMENT POSITIONS ")])])],1)],1),(!hover)?_c('v-img',{attrs:{"contain":"","max-height":"100","src":require('@/assets/abutment-export.svg'),"position":"center center"}}):_vm._e(),(hover)?_c('v-img',{attrs:{"contain":"","max-height":"100","src":require('@/assets/abutment-export-light.svg'),"position":"center center"}}):_vm._e(),_c('v-card-actions')],1),(hover)?_c('v-card',{attrs:{"flat":"","max-width":"225"}},[_c('v-card-text',[_vm._v(" Export implant positions: "),_c('ul',[_c('li',[_vm._v("Choose an OEM Scan Body and connection geometry.")]),_c('li',[_vm._v("Your choice of direct to MUA or Ti Base.")])])])],1):_vm._e()],1)]}}])})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:['rounded-10', 'blue-border', { 'btn-primary': hover }],attrs:{"flat":"","height":"225","width":"225"},on:{"click":function($event){_vm.exportOrOrder = 'order'}}},[_c('v-card-text',{staticClass:"px-1"},[_c('v-row',{staticClass:"d-flex justify-center align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"auto"}},[_c('OrderIcon',{staticClass:"ma-1",staticStyle:{"height":"40px","width":"40px"},attrs:{"color":hover ? 'white' : _vm.iconColor}})],1),_c('v-col',[_c('span',{staticClass:"title-font d-flex justify-center align-center",style:({
                        wordBreak: 'break-word',
                        color: hover ? 'white' : '#147BD1'
                      })},[_vm._v(" ORDER FROM OSTEON ")])])],1)],1),(hover)?_c('v-img',{attrs:{"contain":"","max-height":"100","src":require('@/assets/order-light.svg'),"position":"center center"}}):_vm._e(),(!hover)?_c('v-img',{attrs:{"contain":"","max-height":"100","src":require('@/assets/order.svg'),"position":"center center"}}):_vm._e()],1),(hover)?_c('v-card',{attrs:{"flat":"","max-width":"225"}},[_c('v-card-text',[_vm._v(" Choose your design option: "),_c('ul',[_c('li',[_vm._v("Design Only File (Per Arch) Milled Bar")]),_c('li',[_vm._v("Fully Finished Prosthesis")])])])],1):_vm._e()],1)]}}])})],1),_c('v-col',{attrs:{"cols":"3"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }