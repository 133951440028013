<template>
  <v-card class="rounded-20 mr-4" flat>
    <v-card-title class="title-font">UPLOAD PROJECT FILES </v-card-title>
    <v-card-text class="black--text py-0">
      If your tooth design has been completed, upload each file in the relevant
      upload box:
    </v-card-text>
    <v-form ref="form" lazy-validation>
      <v-card-text class="pa-0">
        <FileUploadAndPreview
          v-model="files.toothDesign"
          categoryTitle="Tooth Design"
          category="tooth_design"
          fileType="scan"
        />
        <FileUploadAndPreview
          v-model="files.tissueOfRestoringArch"
          categoryTitle="Tissue Of Restoring Arch"
          category="tissue_of_restoring_arch"
          fileType="scan"
        />
        <FileUploadAndPreview
          v-model="files.antagonistDentition"
          categoryTitle="Antagonist Dentition"
          category="antagonist_dentition"
          fileType="scan"
        />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import FileUploadAndPreview from "@/components/shared/FileUploadAndPreview.vue"

export default {
  name: "UploadProjectFiles",

  props: ["value"],

  inject: ["form"],

  components: {
    FileUploadAndPreview
  },

  computed: {
    files: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    },

    reset() {
      this.$emit("input", {
        toothDesign: null,
        tissueOfRestoringArch: null,
        antagonistDentition: null
      })
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
