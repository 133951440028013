<template>
  <v-row>
    <v-col>
      <v-card outlined class="rounded-10" max-width="300">
        <v-card-text>
          <DesignViewer :approval-files="approval.approval_files" />
        </v-card-text>
        <v-divider />
        <v-card-subtitle>
          Approval options, only use after carefully reviewing design.
        </v-card-subtitle>
        <v-card-text>
          <ApproveCase />
          <ApproveWithChanges />
          <RequestChanges />
        </v-card-text>
      </v-card>
      <small>
        <span v-if="approval.author_reference">
          <v-icon small> mdi-account </v-icon>
          {{ approval.author_reference }}
          -
        </span>
        {{ approval.created_at | luxon({ output: "short" }) }}
      </small>
    </v-col>
  </v-row>
</template>

<script>
import DesignViewer from "./ApprovalBubble/DesignViewer.vue"
import ApproveCase from "./ApprovalBubble/ApproveCase.vue"
import ApproveWithChanges from "./ApprovalBubble/ApproveWithChanges.vue"
import RequestChanges from "./ApprovalBubble/RequestChanges.vue"

export default {
  props: ["approval"],

  components: { DesignViewer, ApproveCase, ApproveWithChanges, RequestChanges }
}
</script>
