<template>
  <v-card flat class="transparent">
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font">
        SCAN GAUGE SCAN LIBRARY ASSOCIATION
      </v-card-title>
      <v-card-subtitle>
        Carefully select each Scan Gauge used in the kit with the corresponding
        gauge in the scan. Use the round-dimples to check that they match.
      </v-card-subtitle>
    </v-card>

    <v-row>
      <template v-if="!selectedOrder.associated && !showFailure">
        <v-col cols="5">
          <GaugeSelection
            :gauges="gauges"
            :libraryAssociation="selectedGaugeLibraries"
            :active="selectedScanGaugeIndex >= 0"
            :loading="loading"
            v-model="selectedKitGauge"
            @start="scanGaugeSelect(0)"
            @delete="deleteAssociatedGauge"
          />
        </v-col>
        <v-col cols="2">
          <SelectedGauge
            :selectedKitGauge="selectedKitGauge"
            :selectedScanGaugeIndex="selectedScanGaugeIndex"
            @confirm="confirmSelection"
          />
        </v-col>
      </template>
      <v-col cols="7" v-if="selectedOrder.associated || showFailure">
        <AssociationResult
          :associationPass="associationPass"
          :loading="loading"
          @redo="redoAssociation"
        />
      </v-col>

      <v-col cols="5">
        <v-card flat class="mr-4 rounded-20 d-flex flex-column" height="100%">
          <v-card-title class="title-font"> SCAN GAUGE ALIGNMENT </v-card-title>
          <v-card-text
            style="width: 665px; height: 425px"
            class="pa-0 text-center"
          >
            <v-row
              v-if="loading"
              justify="center"
              align="center"
              class="fill-height"
            >
              <v-progress-circular indeterminate size="128" />
            </v-row>
            <div v-show="!loading" ref="view"></div>
          </v-card-text>

          <ScanSelectConfirm
            v-if="
              !selectedOrder.associated &&
              !showFailure &&
              selectedGaugeLibraries.length
            "
            :selectedGaugeLibraries="selectedGaugeLibraries"
            :selectedScanGaugeIndex="selectedScanGaugeIndex"
            :loading="loading"
            @select="scanGaugeSelect"
            @confirm="confirmAssociation"
          />
          <v-card
            flat
            class="mb-2"
            v-if="selectedOrder.associated || showFailure"
          >
            <v-card-title>Visualisation Options</v-card-title>
            <v-card-text>
              <v-form class="d-flex">
                <v-checkbox
                  v-model="showLibraries"
                  label="Show Scan Gauge Libraries"
                />
                <v-checkbox
                  v-model="showConstructedLibraries"
                  label="Show Constructed Libraries"
                />
                <!-- <v-checkbox
                  v-model="showAnalogs"
                  label="Show Implant Analogs"
                /> -->
                <v-checkbox v-model="showScanGauges" label="Show Scan Gauges" />
                <v-checkbox v-model="showScanTissue" label="Show Scan Tissue" />
              </v-form>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import * as NCFrontend from "@osteon-nexus-connect-lib/nc-frontend"
import apiClient from "@/lib/ApiClient"
import GaugeSelection from "./LibraryAssociation/GaugeSelection.vue"
import SelectedGauge from "./LibraryAssociation/SelectedGauge.vue"
import AssociationResult from "./LibraryAssociation/AssociationResult.vue"
import ScanSelectConfirm from "./LibraryAssociation/ScanSelectConfirm.vue"

export default {
  name: "LibraryAssociation",

  components: {
    GaugeSelection,
    SelectedGauge,
    AssociationResult,
    ScanSelectConfirm
  },

  view: null,

  data() {
    return {
      selectedKitGauge: null,
      selectedScanGaugeIndex: -1,
      dialog: false,
      loading: false,
      selectedGaugeLibraries: [],

      showFailure: false,

      enableLibraryAssociation: false,
      libraryAssociationMetrics: null,
      showScanGauges: true,
      showScanTissue: true,
      showLibraries: true,
      showConstructedLibraries: true,
      // showAnalogs: true,

      // mounted() must attach ['scanBodyCode'] to each gauge object
      gauges: [
        [
          {
            kitGaugeId: 1,
            src: require("@/assets/gauges/0000_3-mm-short-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 2,
            src: require("@/assets/gauges/0001_3-mm-short-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 3,
            src: require("@/assets/gauges/0002_3-mm-short-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 4,
            src: require("@/assets/gauges/0003_3-mm-medium-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 5,
            src: require("@/assets/gauges/0004_3-mm-medium-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 6,
            src: require("@/assets/gauges/0005_3-mm-medium-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 7,
            src: require("@/assets/gauges/0006_3-mm-long-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 8,
            src: require("@/assets/gauges/0007_3-mm-long-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 9,
            src: require("@/assets/gauges/0008_3-mm-long-3.png"),
            hemisphereMarkings: 2
          }
        ],
        [
          {
            kitGaugeId: 10,
            src: require("@/assets/gauges/0009_5-mm-short-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 11,
            src: require("@/assets/gauges/0010_5-mm-short-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 12,
            src: require("@/assets/gauges/0011_5-mm-short-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 13,
            src: require("@/assets/gauges/0012_5-mm-medium-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 14,
            src: require("@/assets/gauges/0013_5-mm-medium-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 15,
            src: require("@/assets/gauges/0014_5-mm-medium-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 16,
            src: require("@/assets/gauges/0015_5-mm-long-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 17,
            src: require("@/assets/gauges/0016_5-mm-long-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 18,
            src: require("@/assets/gauges/0017_5-mm-long-3.png"),
            hemisphereMarkings: 3
          }
        ]
      ]
    }
  },

  mounted() {
    this.getNcSessionAndLibraries()
  },

  computed: {
    ...mapGetters(["selectedOrder", "token"]),

    associationPass() {
      return this.libraryAssociationMetrics?.every((m) => m?.pass)
    }
  },

  watch: {
    enableLibraryAssociation(value) {
      this.view.visualisation.enableLibraryAssociation(value)
    },
    showScanGauges(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanGaugesVisible(value)
      }
    },
    showScanTissue(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setScanTissueVisible(value)
      }
    },
    showLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setLibrariesVisible(value)
      }
    },
    showConstructedLibraries(value) {
      if (this.view && this.view.visualisation) {
        this.view.visualisation.setConstructedLibrariesVisible(value)
      }
    }
    // showAnalogs(value) {
    //   if (this.view && this.view.visualisation) {
    //     this.view.visualisation.setAnalogsVisible(value)
    //   }
    // }
  },

  methods: {
    ...mapActions(["setSelectedOrder"]),

    confirmSelection() {
      this.$set(
        this.selectedGaugeLibraries,
        this.selectedScanGaugeIndex,
        this.selectedKitGauge.scanBodyCode
      )

      this.selectedKitGauge = null

      if (this.selectedGaugeLibraries.every((g) => !!g)) {
        this.selectedScanGaugeIndex = -1
        this.view.visualisation.enableLibraryAssociation(true)
      } else {
        this.selectedScanGaugeIndex = this.selectedGaugeLibraries.findIndex(
          (g) => g === null
        )
        this.view.visualisation.zoomToGauge(this.selectedScanGaugeIndex)
      }

      if (this.selectedGaugeLibraries[this.selectedScanGaugeIndex]) {
        const gauge = this.gauges
          .flat()
          .find(
            (g) =>
              g.scanBodyCode ===
              this.selectedGaugeLibraries[this.selectedScanGaugeIndex]
          )
        this.selectedKitGauge = gauge
      }
    },

    async confirmAssociation() {
      try {
        this.loading = true
        const response = await NCFrontend.ApiClient.sessionAssociateLibraries(
          `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
          this.$axios.defaults.headers.Authorization,
          {
            sessionId: this.selectedOrder.nc_session_id,
            kitBoxId: this.selectedOrder.kit_box_id,
            selectedLibraries: this.selectedGaugeLibraries
          }
        )
        this.enableLibraryAssociation = false
        this.libraryAssociationMetrics =
          response.association.libraryAnalyses.map((obj) => {
            return { value: obj.meanPercentiles[2], pass: obj.pass }
          })
        this.view.visualisation.setLibraryAssociationData(response)
        this.view.visualisation.setLibrariesVisible(false)

        this.showScanGauges = false
        this.showScanTissue = true
        this.showLibraries = false
        this.showConstructedLibraries = true
        // this.showAnalogs = true

        if (this.libraryAssociationMetrics?.every((m) => m?.pass)) {
          await apiClient.instance.orders.associateOrder(this.selectedOrder.uid)
          this.setSelectedOrder(
            await apiClient.instance.orders.getOrder(this.selectedOrder.uid)
          )
        } else this.showFailure = true
      } catch (err) {
        console.error("An internal error occurred.")
        throw err
      } finally {
        this.loading = false
      }
    },

    scanGaugeSelect(index) {
      if (
        this.selectedGaugeLibraries[this.selectedScanGaugeIndex] &&
        !this.selectedGaugeLibraries[index]
      )
        this.selectedKitGauge = null

      if (this.selectedScanGaugeIndex === index) {
        this.selectedKitGauge = null
        this.selectedScanGaugeIndex = -1
        return this.view.visualisation.enableLibraryAssociation(true)
      }

      if (this.selectedGaugeLibraries[index]) {
        const gauge = this.gauges
          .flat()
          .find((g) => g.scanBodyCode === this.selectedGaugeLibraries[index])
        this.selectedKitGauge = gauge
      }

      this.selectedScanGaugeIndex = index
      this.view.visualisation.zoomToGauge(index)
    },

    deleteAssociatedGauge(gaugeId) {
      const idx = this.selectedGaugeLibraries.indexOf(gaugeId)
      this.$set(this.selectedGaugeLibraries, idx, null)
    },

    async redoAssociation() {
      this.loading = true
      if (!this.showFailure) {
        await apiClient.instance.orders.redoAnalysis(this.selectedOrder.uid)
        this.setSelectedOrder(
          await apiClient.instance.orders.getOrder(this.selectedOrder.uid)
        )
      } else this.showFailure = false
      this.selectedGaugeLibraries.fill(null)
      this.showScanGauges = true
      this.showScanTissue = true
      this.showLibraries = false
      this.showConstructedLibraries = false
      // this.showAnalogs = false
      this.enableLibraryAssociation = true
      this.loading = false
    },

    async getNcSessionAndLibraries() {
      this.loading = true
      const responses = await Promise.all([
        NCFrontend.ApiClient.sessionGet(
          `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
          this.$axios.defaults.headers.Authorization,
          this.selectedOrder.nc_session_id
        ),
        NCFrontend.ApiClient.gaugeKitGetScanBodyCodes(
          `${apiClient.instanceUrl}/${apiClient.tenantUid}/validation`,
          this.$axios.defaults.headers.Authorization,
          this.selectedOrder.kit_box_id
        )
      ])
      const [sessionResponse, gaugeKitResponse] = responses

      if (!this.$refs.view) return

      this.view = NCFrontend.createView(
        "libraryAssociationView",
        this.$refs.view
      )

      this.selectedGaugeLibraries = new Array(
        sessionResponse.modelScan.gauges.length
      ).fill(null)

      NCFrontend.add(this.view)

      this.view.setVisualisation(
        new NCFrontend.Visualisations.LibraryAssociation(this.view.element, {
          modelScan: sessionResponse.modelScan,
          validation: sessionResponse.validation,
          immediateCase: this.selectedOrder.immediate
        })
      )

      this.gauges = this.gauges.map((cuffHeightSet, setIndex) => {
        const cuffHeight = setIndex === 0 ? "3mm" : "5mm"
        return cuffHeightSet.map((gauge, index) => {
          const offset = cuffHeight === "5mm" ? 9 : 0
          return {
            ...gauge,
            scanBodyCode: gaugeKitResponse.scanBodyCodes[index + offset]
          }
        })
      })

      if (!this.selectedOrder.associated)
        setTimeout(() => {
          this.enableLibraryAssociation = true
          this.loading = false
        }, 0)
      else {
        setTimeout(() => {
          this.view.visualisation.setLibraryAssociationData(
            sessionResponse.libraryAssociation
          )
          this.libraryAssociationMetrics =
            sessionResponse.libraryAssociation.association.libraryAnalyses.map(
              (obj) => {
                return { value: obj.meanPercentiles[2], pass: obj.pass }
              }
            )
          this.showScanGauges = false
          this.showScanTissue = true
          this.showLibraries = false
          this.showConstructedLibraries = true
          this.loading = false
          // this.showAnalogs = true
        }, 0)
      }
    }
  }
}
</script>
