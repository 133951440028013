<template>
  <div>
    <ChooseOrder
      v-if="!forwardedOrderForm.product_code"
      @chooseOrder="chooseOrder"
      @cancelled="cancel"
    />
    <DesignOnlyOrder
      v-if="forwardedOrderForm.product_code === 'DESIGN'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
    <MilledBarOrder
      v-if="forwardedOrderForm.product_code === 'BAR'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ChooseOrder from "./OrderComponent/ChooseOrder"
import MilledBarOrder from "./OrderComponent/MilledBarOrder"
import DesignOnlyOrder from "./OrderComponent/DesignOnlyOrder"

export default {
  name: "OrderComponent",

  components: { ChooseOrder, MilledBarOrder, DesignOnlyOrder },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm"
    ])
  },

  methods: {
    ...mapActions(["setForwardedOrderForm"]),

    chooseOrder(code) {
      this.setForwardedOrderForm({
        ...this.forwardedOrderForm,
        product_code: code
      })
    },

    cancel() {
      this.$emit("cancelled")
    }
  }
}
</script>
