<template>
  <v-row class="my-2">
    <v-col cols="3" class="pr-0 d-flex flex-column align-center">
      <ToothPicker
        v-model="teeth"
        :convention="internationalToothConvention"
        style="max-height: 350px"
        :interactive="interactive ? true : false"
      />
      <v-btn-toggle v-model="internationalToothConvention" class="rounded-10">
        <v-btn x-small value="fdi">FDI</v-btn>
        <v-btn x-small value="palmer">Pal.</v-btn>
        <v-btn x-small value="universal">Uni.</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="mr-4">
      <v-card-title v-if="interactive" class="title-font pl-0">
        MULTI-UNIT ABUTMENT SELECTION
      </v-card-title>
      <v-card-text v-if="showErrors && !muasValid" class="error--text">
        <v-icon class="mr-2" color="error">mdi-close-circle</v-icon>
        {{
          selected.length
            ? "Please select an abutment for each position."
            : "Please select at least one abutment position."
        }}
      </v-card-text>
      <v-card-text v-if="interactive" class="black--text pl-0">
        Confirm the Multi-Unit Abutments used by the clinician. Select the
        location of each MUA in the closest matching tooth on the diagram.
      </v-card-text>
      <v-row>
        <v-col>
          <v-card outlined class="rounded-10">
            <v-card-title class="pa-2 pb-0 secondary--text">
              Upper Arch
            </v-card-title>
            <v-list dense class="mx-2">
              <v-list-item
                v-for="(tooth, index) in selected?.filter(
                  (item) => item.tooth < 30
                )"
                :key="index"
                :style="interactive ? 'min-height: 50px' : ''"
                class="accent my-1 rounded"
              >
                <v-row>
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-list-item-title class="text-center">
                      <strong>
                        {{ getToothNumber(tooth.tooth) }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="10" class="text-caption pa-0 primary--text">
                    <v-autocomplete
                      v-if="interactive"
                      v-model="tooth.implant_platform"
                      :items="implantPlatformItems"
                      label="Select MUA"
                      dense
                      hide-details
                      clearable
                      :menu-props="{ rounded: '10' }"
                      class="mx-4"
                    />
                    <v-list-item-title v-if="!interactive" class="text-center">
                      <strong>
                        {{ tooth.implant_platform.name }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-btn
                      v-if="interactive"
                      icon
                      x-small
                      @click="teeth.splice(teeth.indexOf(tooth.tooth), 1)"
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="rounded-10">
            <v-card-title class="pa-2 pb-0 secondary--text">
              Lower Arch
            </v-card-title>
            <v-list dense class="mx-2">
              <v-list-item
                v-for="(tooth, index) in selected?.filter(
                  (item) => item.tooth > 30
                )"
                :key="index"
                :style="interactive ? 'min-height: 50px' : ''"
                class="accent my-1 rounded"
              >
                <v-row>
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-list-item-title class="text-center">
                      <strong>
                        {{ getToothNumber(tooth.tooth) }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="10" class="text-caption pa-0">
                    <v-autocomplete
                      v-if="interactive"
                      v-model="tooth.implant_platform"
                      :items="implantPlatformItems"
                      label="Select MUA"
                      dense
                      hide-details
                      clearable
                      class="mx-4"
                      :menu-props="{ rounded: '10' }"
                    />
                    <v-list-item-title v-if="!interactive" class="text-center">
                      <strong>
                        {{ tooth.implant_platform.name }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-btn
                      v-if="interactive"
                      icon
                      x-small
                      @click="teeth.splice(teeth.indexOf(tooth.tooth), 1)"
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ToothPicker from "@/components/shared/ToothPicker"
import internationalToothNumbers from "@/components/shared/international_tooth_numbers"

export default {
  name: "MultiUnitConfirmation",

  props: ["order", "value", "responses", "interactive"],

  // inject: ["form"],

  components: { ToothPicker },

  data() {
    return {
      teeth: [],
      internationalToothConvention: "fdi",
      fetchedResponses: [],
      implantPlatforms: [],
      showErrors: false
    }
  },

  computed: {
    ...mapGetters(["country", "orderFormQuestions"]),

    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    muaResponses() {
      if (this.responses) {
        return this.responses
      } else {
        return this.fetchedResponses
      }
    },

    implantPlatformItems() {
      return this.implantPlatforms.map((implant) => {
        return {
          text: implant.name,
          value: implant
        }
      })
    },

    muasValid() {
      return !!(
        this.selected?.every((t) => t.implant_platform_uid) &&
        this.selected?.length
      )
    }
  },

  watch: {
    teeth(newVal) {
      if (newVal.length > this.selected.length) {
        this.selected.push({
          tooth: newVal[newVal.length - 1],
          implant_platform:
            this.selected[this.selected.length - 1]?.implant_platform || ""
        })
      } else if (newVal.length < this.selected.length) {
        this.selected = this.selected.filter((item) =>
          this.teeth.includes(item.tooth)
        )
      }
    },

    selected: {
      handler() {
        this.showErrors = false
      },
      deep: true
    }
  },

  methods: {
    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    },

    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.internationalToothConvention
      ].padStart(2, "0")
    },

    async fetchImplantPlatforms() {
      this.implantPlatforms =
        await client.gateway.implantPlatforms.fetchImplantPlatforms()
    },

    validate() {
      if (!this.muasValid) this.showErrors = true
      return this.muasValid
    },

    reset() {
      this.selected = []
    }
  },

  async mounted() {
    if (!this.responses) {
      this.fetchedResponses = this.availableResponses(
        this.orderFormQuestions.find((item) => item.widget === "tooth_picker")
      )
    }
    this.teeth = this.selected.map((item) => item.tooth)
    this.fetchImplantPlatforms()
  },

  created() {
    this.form?.register(this)
  }
}
</script>
