<template>
  <v-card flat class="card-bg pb-4 rounded-20">
    <v-toolbar color="transparent" flat height="40">
      <v-toolbar-title v-if="filter === 'inbox'" class="pt-3">
        <v-icon>$inboxreceived</v-icon>
        INBOX
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'exported'" class="pt-3">
        <v-icon>$implantexport</v-icon>
        IMPLANTS EXPORTED
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'waiting'" class="pt-3">
        <v-icon>$alarm</v-icon>
        AWAITING RESPONSE
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'all'" class="pt-3">
        <v-icon>$inbox</v-icon>
        ALL CASES
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'archived'" class="pt-3">
        <v-icon>$inboxarchive</v-icon>
        ARCHIVED CASES
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'design'" class="pt-3">
        <v-icon>$toothdesigns</v-icon>
        DESIGN ONLY
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'bar'" class="pt-3">
        <v-icon>$milledbar</v-icon>
        MILLED BARS
      </v-toolbar-title>
    </v-toolbar>
    <v-toolbar color="transparent" flat>
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="inboxFilter"
            class="py-2"
            label="Search"
            placeholder="Search"
            solo
            dense
            flat
            prepend-inner-icon="mdi-magnify"
            hide-details
            background-color="white"
          />
        </v-col>
        <v-col cols="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
            rounded="rounded-lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Range"
                class="py-2"
                readonly
                solo
                dense
                flat
                prepend-inner-icon="mdi-calendar"
                hide-details
                background-color="white"
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="dateRange = []"
              />
            </template>
            <v-date-picker v-model="dateRange" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(dateRange)
                  currentPage = 0
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-list dense class="py-0">
        <v-list-item-group>
          <v-list-item class="ma-0 pt-1" disabled>
            <v-row>
              <v-col cols="1">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-2 font-weight-bold black--text"
                  >
                    Case Number
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="3">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-2 font-weight-bold black--text"
                  >
                    Status
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="3">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-2 font-weight-bold black--text"
                  >
                    Patient Reference
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="2">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-2 font-weight-bold black--text"
                  >
                    Files Received
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="3">
                <v-list-item-content>
                  <v-list-item-title
                    class="text-body-2 font-weight-bold black--text"
                  >
                    Date Received
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list-item-group>
        <v-divider />
      </v-list>
      <v-list dense class="py-2 px-2 overflow-y-auto" max-height="60vh">
        <div
          v-if="loading && displayedItems.length === 0"
          class="text-center ma-5"
        >
          <v-progress-circular indeterminate />
        </div>
        <div
          v-if="!loading && displayedItems.length === 0"
          class="text-center my-10"
        >
          <v-icon class="mr-2">mdi-information</v-icon>
          No Cases Found
        </div>
        <template v-for="(item, index) in displayedItems">
          <v-list-item
            :key="index"
            class="ma-0"
            :class="{ 'gradient-bg-2': !item.viewed }"
            @click="viewInboxItem(item)"
          >
            <v-row align="center">
              <v-col cols="1">
                <v-icon small>$inbox</v-icon>
                {{ item.order_number }}
              </v-col>
              <v-col cols="3">
                <span v-for="(status, index) in item.statuses" :key="index">
                  <v-chip
                    small
                    outlined
                    v-if="status === 'inbox'"
                    class="rounded-10 mr-1"
                  >
                    <v-icon small class="mr-2">$inboxreceived</v-icon>
                    INBOX
                  </v-chip>
                  <v-chip
                    small
                    outlined
                    v-if="status === 'exported'"
                    class="rounded-10 mr-1"
                  >
                    <v-icon small class="mr-2">$implantexport</v-icon>
                    IMPLANTS EXPORTED
                  </v-chip>
                  <v-chip
                    small
                    outlined
                    v-if="status === 'waiting'"
                    class="rounded-10 mr-1"
                  >
                    <v-icon small class="mr-2">$alarm</v-icon>
                    AWAITING RESPONSE
                  </v-chip>
                  <v-chip
                    small
                    outlined
                    v-if="status === 'design'"
                    class="rounded-10 mr-1"
                  >
                    <v-icon small class="mr-2">$toothdesigns</v-icon>
                    DESIGN ONLY
                  </v-chip>
                  <v-chip
                    small
                    outlined
                    v-if="status === 'bar'"
                    class="rounded-10 mr-1"
                  >
                    <v-icon small class="mr-2">$milledbar</v-icon>
                    MILLED BARS
                  </v-chip>
                </span>
              </v-col>
              <v-col cols="3">
                <v-icon color="secondary" small>mdi-account</v-icon>
                {{ item.patient_reference }}
              </v-col>
              <v-col cols="2">
                <v-icon color="secondary" small>mdi-file</v-icon>
                {{ item.files_received }}
                File{{ item.files_received > 1 ? "s" : "" }}
              </v-col>
              <v-col cols="3">
                <v-icon color="secondary" small>mdi-clock</v-icon>
                {{ item.created_at | luxon }}
                <i> ({{ item.created_at | luxonRelative }}) </i>
              </v-col>
            </v-row>
          </v-list-item>
          <v-divider
            :key="item.uid"
            v-if="index !== displayedItems.length - 1"
          ></v-divider>
        </template>
      </v-list>
      <v-divider />
      <v-card-actions>
        <span class="text-subtitle-2 mx-4"
          >Items per page:
          <v-btn
            small
            text
            :color="itemsPerPage === 10 ? 'primary' : ''"
            @click="itemsPerPage = 10"
            >10</v-btn
          >
          <v-btn
            small
            text
            :color="itemsPerPage === 25 ? 'primary' : ''"
            @click="itemsPerPage = 25"
            >25</v-btn
          >
          <v-btn
            small
            text
            :color="itemsPerPage === 50 ? 'primary' : ''"
            @click="itemsPerPage = 50"
            >50</v-btn
          >
        </span>
        <v-spacer />
        <span class="text-subtitle-2">
          {{ displayedItems.length === 0 ? 0 : displayedItemsStartIdx + 1 }} -
          {{ displayedItemsEndIdx }} of {{ filteredOrders.length }}
        </span>
        <v-btn icon @click="currentPage--" :disabled="currentPage === 0">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="currentPage++"
          :disabled="currentPage === pages - 1 || displayedItems.length === 0"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "InboxItems",

  props: ["value", "inboxItems", "loading", "filter"],

  data() {
    return {
      inboxFilter: null,
      currentPage: 0,
      itemsPerPage: 10,
      dateRange: [],
      menu: false
    }
  },

  computed: {
    pages() {
      return Math.ceil(this.filteredOrders.length / this.itemsPerPage)
    },

    displayedItemsStartIdx() {
      return this.currentPage * this.itemsPerPage
    },

    displayedItemsEndIdx() {
      return this.displayedItemsStartIdx + this.itemsPerPage >
        this.filteredOrders.length
        ? this.filteredOrders.length
        : this.displayedItemsStartIdx + this.itemsPerPage
    },

    displayedItems() {
      return this.filteredOrders.slice(
        this.displayedItemsStartIdx,
        this.displayedItemsEndIdx
      )
    },

    filteredOrders() {
      let filteredItems = [...this.inboxItems].sort(
        (b, a) => new Date(a.created_at) - new Date(b.created_at)
      )
      if (this.inboxFilter) {
        filteredItems = filteredItems.filter(
          (item) =>
            item.order_number.toString().includes(this.inboxFilter) ||
            item.client_name
              .toLowerCase()
              .includes(this.inboxFilter.toLowerCase()) ||
            item.patient_reference
              .toLowerCase()
              .includes(this.inboxFilter.toLowerCase())
        )
      }
      if (this.dateRangeModifier.length == 2) {
        filteredItems = filteredItems.filter(
          (item) =>
            new Date(new Date(item.created_at).toISOString().slice(0, 10)) >=
              this.dateRangeModifier[0] &&
            new Date(new Date(item.created_at).toISOString().slice(0, 10)) <=
              this.dateRangeModifier[1]
        )
      }
      return filteredItems
    },

    dateRangeModifier() {
      return this.dateRange.map((date) => new Date(date)).sort((a, b) => a - b)
    },

    dateRangeText() {
      if (this.dateRangeModifier.length === 1) {
        return this.dateRangeModifier[0].toLocaleDateString()
      } else if (this.dateRange.length === 2) {
        return (
          this.dateRangeModifier[0].toLocaleDateString() +
          " - " +
          this.dateRangeModifier[1].toLocaleDateString()
        )
      } else return ""
    }
  },

  methods: {
    ...mapActions(["setSelectedOrder"]),

    dateModifier(date) {
      let hoursDiff = Math.abs(date - new Date()) / 3600000
      if (hoursDiff > 12) {
        return date.toLocaleDateString()
      } else {
        return Math.floor(hoursDiff).toString().concat(" hr")
      }
    },

    viewInboxItem(item) {
      item.viewed = true
      client.instance.orders.viewOrder(item.uid)
      this.setSelectedOrder(item)
      this.$router.push({ name: "caseView", params: { uid: item.uid } })
    }
  },

  watch: {
    inboxFilter() {
      this.currentPage = 0
    }
  }
}
</script>
