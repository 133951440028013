<template>
  <v-card flat class="ml-4 rounded-20">
    <v-card-title class="title-font pb-0"> ORDER DETAILS </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content two-line>
            <v-list-item-title>STATUSES:</v-list-item-title>
            <v-list-item-subtitle>
              <span
                v-for="(status, index) in selectedOrder.statuses"
                :key="index"
              >
                <v-chip outlined small v-if="status === 'inbox'" class="mr-1">
                  <v-icon small class="mr-2">$inboxreceived</v-icon>
                  INBOX
                </v-chip>
                <v-chip
                  outlined
                  small
                  v-if="status === 'exported'"
                  class="mr-1"
                >
                  <v-icon small class="mr-2">$implantexport</v-icon>
                  IMPLANTS EXPORTED
                </v-chip>
                <v-chip outlined small v-if="status === 'waiting'" class="mr-1">
                  <v-icon small class="mr-2">$alarm</v-icon>
                  AWAITING RESPONSE
                </v-chip>
                <v-chip outlined small v-if="status === 'design'" class="mr-1">
                  <v-icon small class="mr-2">$toothdesigns</v-icon>
                  DESIGN ONLY
                </v-chip>
                <v-chip outlined small v-if="status === 'bar'" class="mr-1">
                  <v-icon small class="mr-2">$milledbar</v-icon>
                  MILLED BARS
                </v-chip>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>PATIENT ID:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.patient_reference.toUpperCase() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ORDER NUMBER:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.order_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="selectedOrder.immediate">
          <v-list-item-content>
            <v-list-item-title>CASE TYPE:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.immediate ? "IMMEDIATE" : "FINAL" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>DATE SUBMITTED:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ selectedOrder.created_at | luxon({ output: "date_short" }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider class="mx-2" />
    <v-card-title class="title-font pb-0"> ORDER FORM</v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item
          two-line
          v-for="(question, index) in selectedOrder.responses"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ question.name.toUpperCase() }}:</v-list-item-title
            >
            <v-list-item-subtitle
              v-if="question.widget !== 'tooth_picker'"
              class="font-weight-bold black--text"
            >
              {{ question.value }}
            </v-list-item-subtitle>
            <ToothPicker
              v-if="question.widget === 'tooth_picker'"
              v-model="teeth"
              :interactive="false"
              style="max-height: 300px"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import ToothPicker from "@/components/shared/ToothPicker"

export default {
  name: "PreviewComponent",

  components: { ToothPicker },

  data() {
    return {
      previewTab: null,
      teeth: []
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  },

  mounted() {
    this.toothPickerHandler(
      this.selectedOrder.responses.find(
        (item) => item.widget === "tooth_picker"
      )?.value
    )
  },

  methods: {
    toothPickerHandler(response) {
      this.teeth = response?.map((item) => item.tooth)
    }
  }
}
</script>
