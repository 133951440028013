<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row class="mb-4 ml-4 title-font">
      <span class="font-weight-bold" v-if="currentQuotas">
        &nbsp;{{ kitLimit - kitQuotasRemaining }}&nbsp;
      </span>
      <div v-if="!currentQuotas" class="mr-2">
        <v-progress-circular
          indeterminate
          size="15"
          width="2"
          class="my-auto"
        />
      </div>
      of
      <span class="font-weight-bold"> &nbsp;{{ kitLimit }}&nbsp; </span>
      Scan Gauge Kits Registered
    </v-row>
    <v-row v-if="initialising">
      <v-col class="text-center">
        <v-progress-circular size="128" indeterminate />
      </v-col>
    </v-row>
    <v-row v-if="!initialising && scanGaugeKits?.length === 0">
      <v-col class="text-center">
        You currently have no scan gauge kits associated to your account.
      </v-col>
    </v-row>
    <v-row v-if="!initialising">
      <v-col cols="2" v-for="(kit, index) in scanGaugeKits || []" :key="index">
        <v-card flat class="mx-4 pt-2 rounded-10 text-center">
          <v-img height="150" contain :src="require('@/assets/sgk-gen2.png')" />
          <v-card-title>
            <span class="mx-auto"> {{ prettyKitName(kit.kit_box_id) }} </span>
          </v-card-title>
          <v-card-text> {{ kit.kit_type }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" v-if="kitQuotasRemaining || upgradeable" align="center">
        <v-btn
          width="250px"
          min-height="250px"
          height="100%"
          class="rounded-10 elevation-0"
          style="text-transform: none"
          @click="handleOrder"
        >
          <template v-slot:default>
            <div class="d-flex flex-column" style="opacity: 0.6">
              <v-icon size="120" class="mb-2"> mdi-plus </v-icon>
              <span class="text-body-2"> Purchase another Scan Gauge Kit </span>
            </div>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="mt-10 mb-4 ml-4 title-font"
      v-if="gaugeKitSalesOrders?.length"
    >
      <!-- <span class="font-weight-bold" v-if="gaugeKitSalesOrders">
        &nbsp;{{ gaugeKitSalesOrders.length }}&nbsp;
      </span> -->
      <div v-if="!currentQuotas" class="mr-2">
        <v-progress-circular
          indeterminate
          size="15"
          width="2"
          class="my-auto"
        />
      </div>
      Ordered Scan Gauge Kits
    </v-row>
    <v-row v-if="!initialising">
      <v-col
        cols="2"
        v-for="(salesOrder, index) in gaugeKitSalesOrders || []"
        :key="index"
      >
        <v-card flat class="mx-4 pt-2 rounded-10 text-center">
          <v-img height="150" contain :src="require('@/assets/sgk-gen2.png')" />
          <v-card-title>
            <span class="mx-auto"> {{ salesOrder.order_key }} </span>
          </v-card-title>
          <v-card-text class="text-left ml-3">
            <v-row>
              <v-col>
                <strong>Dispatched: </strong>
                {{ salesOrder.dispatched_at || "No" }}
              </v-col>
            </v-row>
            <v-row v-if="salesOrder.dispatched_at">
              <v-col>
                <strong>Courier: </strong>
                {{ salesOrder.courier }}
              </v-col>
            </v-row>
            <v-row v-if="salesOrder.dispatched_at">
              <v-col>
                <strong>Tracking Number: </strong>
                {{ salesOrder.tracking_number }}
              </v-col>
            </v-row>
            <v-row v-if="salesOrder.dispatched_at">
              <v-col>
                <strong>Tracking Link: </strong>
                {{ salesOrder.tracking_link }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <PurchaseDialog
      v-model="dialog"
      :item="lineItem"
      :loading="loading"
      @confirm="orderScanGaugeKit"
    />
    <!-- :confirmMethod="orderScanGaugeKit" -->
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepEqual } from "@/utils"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import client from "@/lib/ApiClient"

export default {
  name: "ScanGaugeKitsPage",
  components: { PurchaseDialog },

  data() {
    return {
      initialising: false,
      loading: false,
      dialog: false,
      invoice_uid: null,

      lineItem: {
        description: "Scan Gauge Kit",
        amount: "3 995",
        currency: "AUD",
        quantity: 1,
        product_code: "GAUGEKIT-GEN2",
        quota_code: "registered_kits"
      },

      statusTranslations: [
        {
          status: "work_in_progress",
          description: "Manufacturing"
        },
        {
          status: "own_ready_for_dispatch",
          description: "Ready for Dispatch"
        },
        {
          status: "owned",
          description: "Dispatched"
        },
        {
          status: "faulty",
          description: "Faulty"
        }
      ]
    }
  },

  watch: {
    scanGaugeKits(val, prevVal) {
      if (val && !deepEqual(val, prevVal) && this.gaugeKitSalesOrders) {
        this.initialising = false
        this.loading = false
        if (this.dialog) this.dialog = false
      }
    },
    gaugeKitSalesOrders(val, prevVal) {
      if (val && !deepEqual(val, prevVal) && this.scanGaugeKits) {
        this.initialising = false
        this.loading = false
        if (this.dialog) this.dialog = false
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentQuotas",
      "plans",
      "subscription",
      "scanGaugeKits",
      "gaugeKitSalesOrders"
    ]),

    kitQuotasRemaining() {
      const remaining_kit_quotas = this.currentQuotas?.find(
        (q) => q.quota_code === "registered_kits"
      )?.current

      return remaining_kit_quotas
    },

    kitLimit() {
      return this.currentQuotas?.find((q) => q.quota_code === "registered_kits")
        ?.incoming
    },

    upgradeable() {
      const currentPlan = this.plans.find(
        (p) => p.code === this.subscription.plan_code
      )
      const upgradeable = this.plans.some(
        (p) => p.subscription_price > currentPlan.subscription_price
      )
      return upgradeable
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas", "triggerReload"]),

    // async fetchGaugeKits() {
    //   this.initialising = true
    //   this.scanGaugeKits = await client.gateway.gaugeKits.fetchScanGaugeKits()
    //   this.initialising = false
    // },

    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    },

    mapKitStatus(kitStatus) {
      const status = this.statusTranslations.find((x) => x.status === kitStatus)
      if (!status) {
        return "Unknown"
      }
      return status.description
    },

    handleOrder() {
      if (!this.kitQuotasRemaining)
        this.$router.push({ name: "profilePage", params: { change: true } })

      this.dialog = true
    },

    async poll() {
      if (!this.invoice_uid) return
      const invoice_paid = await client.gateway.billing.checkInvoice(
        this.invoice_uid
      )
      if (invoice_paid) {
        this.triggerReload("quotas")
        this.triggerReload("scanGaugeKits")
        this.triggerReload("gaugeKitSalesOrders")
        // this.dialog = false
      } else setTimeout(this.poll, 500)
    },

    async orderScanGaugeKit(paymentMethodId) {
      this.loading = true
      try {
        this.invoice_uid = await client.gateway.gaugeKits.orderScanGaugeKit(
          paymentMethodId
        )
      } catch {
        // Error states?
        console.error("Failed to order Scan Gauge Kit.")
        this.loading = false
        return
      }
      this.poll()
    }
  },

  mounted() {
    if (this.scanGaugeKits === null || this.gaugeKitSalesOrders === null)
      this.initialising = true
    this.triggerReload("scanGaugeKits", "gaugeKitSalesOrders")
  }
}
</script>
