<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font">
        <v-icon class="mr-2">mdi-cog</v-icon>
        SETTINGS
      </v-toolbar-title>
    </v-toolbar>
    <v-card v-if="!settings" flat class="mx-4 rounded-10 pb-4" align="center">
      <v-progress-circular indeterminate class="mt-4" />
    </v-card>
    <v-card v-if="settings" flat class="mx-4 rounded-10 pb-4">
      <v-card-text class="black--text">
        <v-row>
          <v-col cols="12">
            <v-row align="center" class="text-subtitle-2 px-5">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              Manage Clients
              <v-spacer />
              <v-switch v-model="settings.manage_clients" inset />
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="center" class="text-subtitle-2 px-5">
              <v-icon class="mr-2"> $surgicalTool </v-icon>
              Immediate Workflow
              <v-spacer />
              <v-switch v-model="settings.immediate_workflow" inset />
            </v-row>
          </v-col>
          <!-- !! -->
          <template v-if="featureFlags.custom_branding">
            <v-col cols="12">
              <v-row align="center" class="text-subtitle-2 px-5 py-4">
                <v-icon class="mr-2"> mdi-palette </v-icon>
                Theme
                <v-spacer />
                <v-btn class="btn-primary" @click="themeDialog = true">
                  Customise Theme
                </v-btn>
                <v-dialog
                  v-model="themeDialog"
                  width="fit-content"
                  content-class="elevation-0"
                >
                  <ThemePreview
                    v-model="settings"
                    :savedColours="savedSettings.colours"
                    @close="themeDialog = false"
                  />
                </v-dialog>
              </v-row>
            </v-col>

            <v-col cols="12">
              <v-row align="center" class="text-subtitle-2 px-5 py-3">
                <v-icon class="mr-2">mdi-panorama-variant</v-icon>
                Custom Logo
                <v-spacer />
                <v-file-input
                  :label="
                    settings.logo_url
                      ? 'Upload a new Logo'
                      : 'Upload your Business Logo'
                  "
                  width="300px"
                  full-width
                  outlined
                  dense
                  hide-details
                  :clearable="false"
                  v-model="logoFile"
                  @change="handleLogoUpload"
                />
                <v-btn
                  v-if="settings?.logo_url"
                  class="btn-primary ml-3"
                  @click="logoPreview = true"
                >
                  Preview
                </v-btn>
              </v-row>
              <v-dialog v-model="logoPreview" max-width="500">
                <div class="white pa-2">
                  <v-img
                    max-width="500"
                    max-height="500"
                    contain
                    :src="settings.logo_url"
                  />
                </div>
              </v-dialog>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer />
      <v-btn
        text
        color="error"
        disabled
        class="rounded-10"
        v-if="unsavedChanges"
      >
        <v-icon class="mr-2">mdi-alert</v-icon>
        Unsaved Changes
      </v-btn>
      <v-btn class="btn-primary" @click="save" :disabled="loading || !settings">
        <span v-if="!loading">Save</span>
        <v-progress-circular v-if="loading" indeterminate />
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ThemePreview from "./Settings/ThemePreview"
import client from "@/lib/ApiClient"
import { deepEqual, deepCopy } from "@/utils"

export default {
  name: "SettingsCard",

  components: {
    ThemePreview
  },

  data() {
    return {
      loading: false,
      settings: null,
      logoFile: null,
      themeDialog: false,
      logoPreview: false
    }
  },

  watch: {
    savedSettings(val, prevVal) {
      if (val && prevVal === null) this.initialise()
    }
  },

  computed: {
    ...mapGetters({
      savedSettings: "settings",
      reloadQueue: "reloadQueue",
      featureFlags: "featureFlags"
    }),

    unsavedChanges() {
      if (this.settings === null) return false
      return !deepEqual(this.settings, this.savedSettings)
    }
  },

  methods: {
    ...mapActions({
      triggerReload: "triggerReload",
      setSavedSettings: "setSettings"
    }),

    async save() {
      this.loading = true
      await client.instance.tenants.updateSettings(this.settings)
      await this.fetch()
      this.loading = false
    },

    async fetch() {
      this.loading = true
      const response = await client.instance.tenants.fetchSettings()
      this.setSavedSettings(response)
      this.settings = deepCopy(this.savedSettings)
      this.loading = false
    },

    async handleLogoUpload(file) {
      this.loading = true
      const formData = new FormData()
      formData.append("file", file, file.name)
      const response = await client.instance.tenants.uploadLogo(formData)
      this.settings.logo_url = response.logo_url
      this.loading = false
    },

    initialise() {
      this.settings = deepCopy(this.savedSettings)
      this.loading = false
    }
  },

  beforeMount() {
    // Is there ever a need to trigger reload on settings?
    if (this.savedSettings) this.initialise()
  }
}
</script>
