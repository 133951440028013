<template>
  <v-card class="rounded-20 ml-4 mt-7" flat>
    <v-card-title class="title-font"> SELECT ABUTMENT EXPORT </v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-select
          v-model="abutmentAnalogPositions"
          label="Select Abutment Export"
          :rules="rules"
          outlined
          dense
          :items="filteredScanBodyLibraries"
          :menu-props="{ rounded: '10' }"
        >
        </v-select>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon"
import { mapGetters } from "vuex"
import { scanBodyLibraries } from "@/utils"
import client from "@/lib/ApiClient"

export default {
  name: "SelectAbutmentExport",

  props: ["value"],

  inject: ["form"],

  data() {
    return {
      historyExports: [],
      rules: [(v) => !!v || "Required"]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"]),

    abutmentAnalogPositions: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    filteredScanBodyLibraries() {
      return this.historyExports.map((ex) => {
        let library = scanBodyLibraries.find((lib) => lib.value === ex.library)
        library = { ...library }
        const formattedCreatedAt = DateTime.fromISO(
          ex.created_at
        ).toLocaleString()
        library.text = `${formattedCreatedAt} - ${library.text}`
        return library
      })
    }
  },

  methods: {
    async fetchHistoryExports() {
      this.loading = true
      this.historyExports = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      this.historyExports.sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      )
      this.loading = false
    },

    validate() {
      return this.$refs.form.validate()
    },

    reset() {
      this.$emit("input", null)
    }
  },

  async mounted() {
    await this.fetchHistoryExports()
  },

  created() {
    this.form.register(this)
  }
}
</script>
