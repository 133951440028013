<template>
  <v-card flat class="card-bg">
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font">
        ORDER FORM
        <v-spacer />
        <v-btn class="my-auto" icon :to="{ name: 'inboxPage' }">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card flat class="ma-4 rounded-20">
      <v-form ref="orderForm">
        <v-card-text align="center">
          <v-progress-circular indeterminate size="64" v-if="initialising" />
        </v-card-text>
        <v-card-text v-if="!initialising">
          <v-row v-if="settings.manage_clients">
            <v-col>
              <v-autocomplete
                v-model="form.client_uid"
                :items="clientItems"
                outlined
                dense
                label="Client (Required)"
                :rules="rule"
                :menu-props="{ rounded: '10' }"
              />
            </v-col>
            <v-col cols="auto">
              <v-btn class="btn-primary" text @click="newClientDialog = true">
                New
              </v-btn>
              <ClientModal
                v-model="newClientDialog"
                :client="newClient"
                form-title="New Client"
                :loading="loading"
                @close="closeNewClientModal"
                @save="saveNewClient"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-if="!settings.manage_clients"
            v-model="form.client_name"
            outlined
            dense
            label="Client (Required)"
            :rules="rule"
          />
          <v-text-field
            v-model="form.patient_reference"
            outlined
            dense
            label="Patient Reference (Required)"
            :rules="rule"
          />

          <ScanGaugeKitSelector v-model="form.kit_box_id" />

          <NewOrderCaseType
            v-model="form.immediate"
            v-if="settings.immediate_workflow"
          />

          <template v-if="scanGaugeKits?.length">
            <NewOrderScanUploadAndValidate
              :loading="loading"
              :sessionId="ncSessionId"
              :leftToRight="leftToRightScan"
              :rightToLeft="rightToLeftScan"
              :validateScansRes="validateScansResponse"
              @update="updateInstance"
            />

            <AdditionalFileUploader
              :scanFiles="scanFileUploads"
              :photoFiles="photoFileUploads"
              :otherFiles="otherFileUploads"
              @updateFiles="updateInstance"
            />

            <NewOrderOrderForm v-model="form" />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="btn-error"
            :to="{ name: 'inboxPage' }"
            :disabled="loading || initialising"
          >
            Close
          </v-btn>
          <v-btn
            text
            class="ma-2 btn-primary rounded-10"
            @click="createOrder"
            :loading="loading"
            :disabled="loading || initialising || !scanGaugeKits?.length"
          >
            Submit
          </v-btn>
          <v-dialog v-model="submitting" width="200px" persistent>
            <v-card class="pa-5">
              <v-progress-circular indeterminate class="ml-3 mr-5" />
              <span class="title-font">Processing</span>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ClientModal from "@/components/ClientPage/ClientModal.vue"
import ScanGaugeKitSelector from "./NewOrder/ScanGaugeKitSelector.vue"
import AdditionalFileUploader from "./NewOrder/AdditionalFileUploader.vue"
import NewOrderOrderForm from "./NewOrder/NewOrderOrderForm.vue"
import NewOrderScanUploadAndValidate from "./NewOrder/NewOrderScanUploadAndValidate.vue"
import NewOrderCaseType from "./NewOrder/NewOrderCaseType.vue"

export default {
  name: "NewOrder",

  components: {
    NewOrderOrderForm,
    ClientModal,
    ScanGaugeKitSelector,
    AdditionalFileUploader,
    NewOrderScanUploadAndValidate,
    NewOrderCaseType
  },

  data() {
    return {
      initialising: true,
      loading: false,
      submitting: false,
      ncSessionId: null,
      leftToRightScan: null,
      rightToLeftScan: null,
      validateScansResponse: null,
      newClientDialog: false,
      newClient: {},
      form: {
        responses: []
      },
      scanFileUploads: [],
      photoFileUploads: [],
      otherFileUploads: [],
      rule: [(v) => !!v || "Required"],
      internationalToothConvention: "fdi"
    }
  },

  watch: {
    clients(val, prevVal) {
      if (val && this.settings && prevVal === null) {
        this.initialising = false
      }
    },

    settings(val, prevVal) {
      if (val && this.clients && prevVal === null) {
        this.initialising = false
      }
    },

    scanGaugeKits(val) {
      if (val?.length === 0) this.$router.push({ name: "inboxPage" })
      if (val?.length === 1) this.form.kit_box_id = val[0].kit_box_id
    }
  },

  computed: {
    ...mapGetters(["clients", "settings", "scanGaugeKits"]),

    clientItems() {
      return this.users.map((client) => {
        return {
          text: client.name,
          value: client.uid
        }
      })
    },

    users() {
      if (this.clients === null) return []
      return this.clients
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    scrollToFirstInvalidField(element) {
      const children = element?.$children
      if (!children || !children?.length) return

      for (let child of children) {
        if (Object.keys(child).includes("valid") && !child.valid) {
          this.$nextTick(() => {
            const element = child.$el
            element.scrollIntoView({ behavior: "smooth", block: "center" })
          })
          return null
        }
        if (this.scrollToFirstInvalidField(child) === null) return null
      }
    },

    async createOrder() {
      const formIsValid = this.$refs.orderForm.validate()
      const scoreKey = this.immediate ? "scoreImmediate" : "scoreRestorative"

      if (
        formIsValid &&
        this.validateScansResponse?.validation[scoreKey] >= 4
      ) {
        this.submitting = true
        this.loading = true
        const submitted_at = new Date().toISOString()
        await client.instance.orders
          .createOrder({
            ...this.form,
            nc_session_id: this.ncSessionId,
            submitted_at
          })
          .then(async (order) => {
            await this.uploadFiles(
              order.uid,
              [
                this.leftToRightScan,
                this.rightToLeftScan,
                ...this.scanFileUploads
              ],
              "scan"
            )
            await this.uploadFiles(order.uid, this.photoFileUploads, "photo")
            await this.uploadFiles(order.uid, this.otherFileUploads, "other")
          })
          .catch(() => {
            this.submitting = false
            this.loading = false
            // Error handling?
            return console.error("Submission Failed")
          })
        this.submitting = false
        this.loading = false
        this.$router.push({ name: "inboxPage" })
        this.$emit("submission")
      } else {
        this.scrollToFirstInvalidField(this.$refs.orderForm)
      }
    },

    async uploadFiles(orderUid, files, fileType) {
      const formData = new FormData()
      formData.append("file_type", fileType)
      files.forEach((file) => {
        formData.append("files", file, file.name)
      })
      await client.instance.orders.uploadFiles(orderUid, formData)
    },

    updateInstance(key, value) {
      this[key] = value
    },

    closeNewClientModal() {
      this.newClient = {}
      this.newClientDialog = false
    },

    async saveNewClient() {
      this.loading = true
      this.newClient.id_strings = [
        this.newClient.email_address,
        this.newClient.phone_number
      ]
      const newClient = await client.instance.clients.createClient(
        this.newClient
      )
      this.form.client_uid = newClient.uid
      this.triggerReload("clients")
      this.loading = false
      this.closeNewClientModal()
    }
  },

  async mounted() {
    this.initialising = true
    if (this.clients && this.settings) this.initialising = false
    else this.triggerReload("clients", "settings")
  }
}
</script>
