<template>
  <v-card class="mr-4 rounded-20 mt-4" flat>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-error" elevation="0" @click="$emit('step', 1)">
        Cancel
      </v-btn>
      <v-btn class="btn-primary white--text my-4 mx-2" @click="$emit('submit')">
        Submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SubmitOrCancel"
}
</script>
