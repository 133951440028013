var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',{staticClass:"py-1"},[_c('v-row',{staticClass:"mb-3",attrs:{"no-gutters":""}},_vm._l((_vm.selectedGaugeLibraries),function(_,index){return _c('v-col',{key:index,staticClass:"my-1",attrs:{"align":"center"}},[_c('v-badge',{attrs:{"value":index === _vm.selectedScanGaugeIndex && _vm.selectedGaugeLibraries[index],"left":"","dot":"","color":"primary"}},[_c('v-btn',{class:[
            { 'btn-primary': index === _vm.selectedScanGaugeIndex },
            { 'btn-success ': _vm.selectedGaugeLibraries[index] },
            'title-font',
            'rounded-10'
          ],attrs:{"small":"","elevation":"0"},on:{"click":function($event){return _vm.$emit('select', index)}}},[_vm._v(" "+_vm._s(`Scan Gauge - ${index + 1}`)+" ")])],1)],1)}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{class:[
          { 'rounded-10': true },
          { 'mb-2': true },
          { 'btn-success': _vm.readyToComplete }
        ],attrs:{"elevation":"0","disabled":!_vm.readyToComplete},on:{"click":function($event){return _vm.$emit('confirm')}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Confirm Association ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }