<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1 grey-border"
    style="cursor: pointer; user-select: none; background-color: #f5f5f5"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
    :disabled="disabled || loading"
  >
    <div class="d-flex flex-column">
      <v-progress-circular indeterminate v-if="loading" />
      <span v-if="!loading">
        <v-icon class="mr-1">$upload</v-icon>
        UPLOAD
      </span>
      <i v-if="!loading" style="font-size: 10px; text-transform: none">
        Click or Drag and Drop to upload
      </i>
      <i
        v-if="!loading && (invalidFile || filesToUpload.length > 1)"
        style="font-size: 10px; text-transform: none"
        class="error--text"
      >
        <span v-if="filesToUpload.length > 1">
          Only one file can be uploaded.
        </span>
        <span v-if="invalidFile && fileExtension.length === 1">
          File type must be .{{ fileExtension[0] }}
        </span>
        <span v-if="invalidFile && fileExtension.length > 1">
          File type must be one of:
          <span v-for="(ext, index) in fileExtension" :key="index">
            <span v-if="index !== 0">, </span>.{{ ext }}
          </span>
        </span>
      </i>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "FileUploader",

  props: ["text", "fileExtension", "value", "category", "disabled", "newOrder"],

  watch: {
    value(value) {
      if (value) {
        this.invalidFile = false
        this.filesToUpload = []
      }
    }
  },

  data() {
    return {
      loading: false,
      dragOver: false,
      filesToUpload: [],
      filesUploaded: [],
      uploadProgress: 0,
      inputDOMElement: null,
      invalidFile: false
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  },

  methods: {
    fileExtensionCheck(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      return this.fileExtension.includes(ext)
    },

    fileType(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      if (["stl", "ply"].includes(ext)) return "scan"
      if (["png", "jpg", "jpeg"].includes(ext)) return "photo"
      else return "other"
    },

    async addFile() {
      this.filesToUpload = []
      this.invalidFile = false
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = false
        this.inputDOMElement.addEventListener("change", async (event) => {
          let files = [...event.target.files]
          files.forEach((entry) => {
            if (this.fileExtensionCheck(entry)) this.filesToUpload.push(entry)
            else this.invalidFile = true
          })
          if (this.newOrder) this.$emit("input", this.filesToUpload[0])
          else {
            const formData = new FormData()
            if (this.filesToUpload.length > 1) return
            this.filesToUpload.forEach((file) => {
              formData.append("file_category", this.category)
              formData.append("file_type", this.fileType(file))
              formData.append("files", file, file.name)
            })

            this.loading = true
            const response = await client.instance.orders.uploadFiles(
              this.selectedOrder.uid,
              formData
            )
            this.$emit("input", response.data[0])
            this.loading = false
          }
        })
      }
      this.inputDOMElement.click()
    },

    async onDrop(event) {
      this.dragOver = false
      this.filesToUpload = []
      this.invalidFile = false
      ;[...event.dataTransfer.files].forEach((element) => {
        if (this.fileExtensionCheck(element)) this.filesToUpload.push(element)
        else this.invalidFile = true
      })
      if (this.newOrder) this.$emit("input", this.filesToUpload[0])
      else {
        const formData = new FormData()
        if (this.filesToUpload.length > 1) return
        this.filesToUpload.forEach((file) => {
          formData.append("file_type", this.fileType(file))
          formData.append("file_category", this.category)
          formData.append("files", file, file.name)
        })
        this.loading = true
        const response = await client.instance.orders.uploadFiles(
          this.selectedOrder.uid,
          formData
        )
        this.$emit("input", response.data[0])
        this.loading = false
      }
    }
  }
}
</script>
