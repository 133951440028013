<template>
  <v-dialog v-model="dialog" width="90vw" content-class="rounded-20">
    <v-card flat class="rounded-20" height="70vh">
      <v-card-title class="title-font"> SCANS &amp; FILES </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-list
              dense
              class="transparent pa-0"
              v-for="(category, index) in categories"
              :key="index"
            >
              <v-list-item
                class="mb-2 custom-grey"
                :input-value="selectedCategory === category.value"
                @click="selectedCategory = category.value"
              >
                <v-list-item-content class="title-font">
                  <v-list-item-title class="text-wrap">
                    {{ category.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">
                    {{ category.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-chip
                  small
                  :color="fileCount(category.value) > 0 ? 'primary' : ''"
                  class="rounded-10"
                >
                  {{ fileCount(category.value) }}
                </v-chip>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="10">
            <v-card
              flat
              class="rounded-10 overflow-y-auto custom-grey"
              max-height="65vh"
            >
              <v-card-text>
                <v-row v-if="!filteredFiles.length && loading">
                  <v-col cols="3" v-for="index in 4" :key="index">
                    <v-skeleton-loader type="image" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="text-center"
                    v-if="!filteredFiles.length && !loading"
                  >
                    No files found
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="(file, index) in filteredFiles"
                    :key="index"
                  >
                    <v-card
                      flat
                      class="rounded-10 d-flex flex-column"
                      height="100%"
                      v-if="!loading"
                    >
                      <v-row align="center">
                        <v-col>
                          <v-img
                            v-if="fileType(file) === 'image'"
                            height="150"
                            contain
                            :src="file.url"
                          />
                          <ModelViewer
                            v-if="fileType(file) === 'model'"
                            :filename="file.filename"
                            :url="file.url"
                            :height="150"
                          />
                          <v-card
                            v-if="
                              fileType(file) === 'download' ||
                              fileType(file) === 'html'
                            "
                            flat
                            max-height="150"
                          >
                            <v-card-text class="text-center">
                              <v-icon>mdi-information</v-icon>
                              Cannot Preview File
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-card-text class="text-center">
                        <p class="off rounded-10">
                          {{ file.filename }}
                        </p>
                        <v-btn
                          small
                          class="btn-primary"
                          @click="handleSelection(file)"
                        >
                          Select
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  props: ["value", "category", "open"],

  components: { ModelViewer },

  data() {
    return {
      loading: false,
      selectedCategory: "",
      categoryItems: [
        {
          title: "Antagonist Dentition",
          subtitle: "",
          value: "antagonist_dentition"
        },
        {
          title: "Tooth Design",
          subtitle: "",
          value: "tooth_design"
        },
        {
          title: "Tissue of Restoring Arch",
          subtitle: "",
          value: "tissue_of_restoring_arch"
        },
        {
          title: "Existing Prosthesis",
          subtitle: "In-Situ Scan",
          value: "existing_prosthesis_in_situ_scan"
        },
        {
          title: "Tissue of Restoring Arch",
          subtitle: "Using Narrow Scan Bodies",
          value: "tissue_of_restoring_arch_using_narrow_scan_bodies"
        },
        {
          title: "Existing Prosthesis Extra-Orally 360",
          subtitle: "Using Scan Analogs",
          value: "existing_prosthesis_extra_orally_360_using_scan_analogs"
        },
        {
          title: "Antagonist Dentition",
          subtitle: "",
          value: "antagonist_dentition"
        },
        {
          title: "Bite Scan",
          subtitle: "",
          value: "bite_scan"
        },
        {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite",
          value: "full_face_smiling_with_relaxed_bite"
        },
        {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite using Retractors",
          value: "full_face_smiling_with_relaxed_bite_using_retractors"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderFiles"]),

    files() {
      return this.orderFiles
    },

    categories() {
      return [
        { title: "All Uncategorized Files", subtitle: "", value: "" },
        this.categoryItems.find((category) => category.value === this.category)
      ]
    },

    filteredFiles() {
      if (!this.selectedCategory) {
        return this.files.filter((file) => file.file_category === "")
      }
      return this.files.filter(
        (file) => file.file_category === this.selectedCategory
      )
    },

    dialog: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit("close", value)
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    fileCount(category) {
      return this.files.filter((file) => file.file_category === category).length
    },

    fileType(file) {
      if (!file?.filename) {
        return
      }
      const fileExt = file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    async handleSelection(file) {
      if (!["model", "image"].includes(this.fileType(file))) {
        return
      }
      await client.instance.orders.updateFileCategory(
        this.selectedOrder.uid,
        file.uid,
        this.category
      )
      this.$emit("input", file)
    },

    async fetchOrderFiles() {
      this.loading = true
      this.triggerReload("files")
      this.loading = false
    }
  },

  mounted() {
    this.fetchOrderFiles()
    this.selectedCategory = this.category
  }
}
</script>
