<template>
  <v-card flat class="mr-4 rounded-20">
    <v-card-title class="title-font">
      CASE CHAT
      <v-spacer />
      <v-tooltip top class="rounded-10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!fullmode"
            @click="$emit('fullmode')"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-arrow-expand-all </v-icon>
          </v-btn>
        </template>
        <span>Expand Chat</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>
      Use the Case Chat for all case related information and discussion
    </v-card-subtitle>
    <v-card-text>
      <MessageComponent
        :messages="messages"
        :approvals="approvals"
        :loading-message="loadingMessage"
        :sending-message="sendingMessage"
        @compose="sendMessage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import MessageComponent from "./CaseChat/MessageComponent.vue"

export default {
  components: { MessageComponent },

  props: ["fullmode"],

  data() {
    return {
      loadingMessage: false,
      sendingMessage: false,
      approvals: []
    }
  },

  watch: {
    orderMessages(val) {
      if (val) {
        this.loadingMessage = false
        this.sendingMessage = false
      }
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "orderMessages"]),

    messages() {
      if (this.orderMessages === null) return []
      return this.orderMessages
    }
  },

  methods: {
    ...mapActions(["triggerReload"]),

    async fetchMessages() {
      if (this.orderMessages === null) this.loadingMessage = true
      this.triggerReload("messages")
    },

    async sendMessage(formData) {
      this.sendingMessage = true
      await client.instance.messages.sendMessage(
        this.selectedOrder.uid,
        formData
      )
      this.fetchMessages()
    }
  },

  mounted() {
    this.fetchMessages()
  }
}
</script>
