<template>
  <v-card flat class="ma-4 ml-0 rounded-20 overflow-hidden">
    <v-card-title class="title-font">
      EXISTING ORDERS
      <v-progress-circular class="ml-5" v-if="loading" indeterminate />
    </v-card-title>
    <v-card-text v-if="!existingOrders.length">
      There are no Existing Orders.
    </v-card-text>
    <v-simple-table v-if="existingOrders.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Type</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) in existingOrders"
            :key="index"
            style="cursor: pointer"
            @click="handleOrderClick(order)"
          >
            <td>
              <v-icon size="40">
                {{ determineIconFromProduct(order.product) }}
              </v-icon>
            </td>
            <td>{{ order.created_at | luxonRelative }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <template v-if="partialOrders.length">
      <v-card-title class="title-font">
        PARTIALLY COMPLETE ORDERS
        <v-progress-circular class="ml-5" v-if="loading" indeterminate />
      </v-card-title>
      <v-card-text> Click on an order to continue. </v-card-text>
      <v-simple-table v-if="partialOrders.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Type</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in partialOrders"
              :key="order.uid"
              style="cursor: pointer"
              @click="handleOrderClick(order)"
            >
              <td>
                <v-icon size="40">
                  {{ determineIconFromProduct(order.product) }}
                </v-icon>
              </td>
              <td>
                {{ order.created_at | luxon({ output: "short" }) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "ExistingAndPartialOrders",

  data() {
    return {
      partialOrders: [],
      existingOrders: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"])
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder", "triggerReload"]),

    determineIconFromProduct(product_code) {
      switch (product_code) {
        case "BAR":
          return "$milledbar"
        case "DESIGN":
          return "$toothdesigns"
      }
    },

    async handleOrderClick(order) {
      this.setSelectedForwardedOrder(order)
      this.triggerReload("forwardedOrder")
    },

    async getForwardedOrders() {
      this.loading = true
      const result = await client.instance.orders.getForwardedOrders(
        this.selectedOrder.uid
      )
      this.partialOrders = result
        .filter((o) => !o.submitted_at)
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      this.existingOrders = result
        .filter((o) => !!o.submitted_at)
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      this.loading = false
    }
  },

  mounted() {
    this.getForwardedOrders()
  }
}
</script>
