<template>
  <v-card class="rounded-20" flat>
    <v-card-title class="title-font"> SPECIAL REQUESTS </v-card-title>
    <v-card-text class="black--text">
      Add any case related comments below:
    </v-card-text>
    <v-textarea
      v-model="text"
      label="Special Requests"
      full-width
      outlined
      class="mx-4"
      hide-details
    />
  </v-card>
</template>

<script>
import { debounce } from "@/utils"
export default {
  name: "SpecialRequests",

  props: ["value", "order"],

  inject: ["form"],

  data() {
    return { text: "" }
  },

  mounted() {
    this.text = this.specialRequests || ""
  },

  watch: {
    text(value) {
      this.debouncedTextChange(value)
    }
  },

  computed: {
    specialRequests: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    handleTextChange(text) {
      this.specialRequests = text
    },

    validate() {
      return true
    },

    reset() {
      this.$emit("input", null)
    }
  },

  created() {
    this.form.register(this)
    this.debouncedTextChange = debounce(this.handleTextChange, 500)
  }
}
</script>
