<template>
  <v-container fluid class="pa-0 card-bg">
    <v-container fluid v-if="!selectedOrder" class="pa-4 card-bg">
      <v-skeleton-loader type="heading" class="my-5" />
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-if="!initialising" class="pa-0 card-bg">
      <v-row class="mt-1">
        <v-col cols="12">
          <v-tabs align-with-title class="rounded-20 px-4" v-model="tabIndex">
            <v-tab @click="changeTab('overview')">Overview</v-tab>
            <v-tab @click="changeTab('library')">
              <v-icon
                v-if="!selectedOrder.associated"
                color="warning"
                small
                class="mr-2"
              >
                mdi-alert-circle
              </v-icon>
              <v-icon
                v-if="selectedOrder.associated"
                color="success"
                small
                class="mr-2"
              >
                mdi-check-circle
              </v-icon>
              Library Association
            </v-tab>
            <v-tab @click="changeTab('export-order')">
              <v-icon
                v-if="!selectedOrder.associated"
                color="error"
                small
                class="mr-2"
              >
                mdi-alert
              </v-icon>
              Export &amp; Order
            </v-tab>
            <v-tab @click="changeTab('chat')">Chat &amp; Notes</v-tab>
            <v-tab @click="changeTab('scans-files')">Scans &amp; Files</v-tab>
            <!-- v HIDDEN for Demo. -->
            <!-- <v-tab @click="changeTab('order-form')">Order Form</v-tab> -->
            <v-spacer />
            <v-btn class="my-auto mr-2" icon @click="closeOrder">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-tabs>
        </v-col>
      </v-row>

      <v-card
        v-show="tab === 'overview'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <v-row>
          <v-col cols="4">
            <PreviewComponent />
          </v-col>
          <v-col cols="4">
            <AlignmentScan
              @toggleViewLibAssociation="changeTab('library')"
              @redo="redoAnalysis"
            />
          </v-col>
          <v-col cols="4">
            <CaseChat @fullmode="changeTab('chat')" />
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-show="tab === 'library'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <LibraryAssociation />
      </v-card>

      <v-card
        v-if="['order', 'export', 'export-order'].includes(tab)"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ExportOrOrder v-if="selectedOrder.associated" />

        <div v-if="!selectedOrder.associated">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> EXPORT &amp; ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="changeTab('library')">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 'chat'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <CaseChat class="ma-4" :fullmode="true" />
      </v-card>

      <v-card
        v-show="tab === 'scans-files'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ScansAndFiles />
      </v-card>

      <v-card
        v-if="tab === 'order-form'"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        Order forms
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"

import PreviewComponent from "./OrderView/PreviewComponent"
import AlignmentScan from "./OrderView/AlignmentScan"
import ExportOrOrder from "./OrderView/ExportOrOrder"
import CaseChat from "@/components/shared/CaseChat"
import LibraryAssociation from "./OrderView/LibraryAssociation"
import ScansAndFiles from "./OrderView/ScansAndFiles"

export default {
  name: "OrderView",

  props: ["uid"],

  components: {
    PreviewComponent,
    AlignmentScan,
    ExportOrOrder,
    CaseChat,
    LibraryAssociation,
    ScansAndFiles
  },

  data() {
    return {
      loading: false,
      initialising: true,
      tab: "",
      tabs: [
        "overview",
        "library",
        "export-order",
        "chat",
        "scans-files",
        "order-form"
      ],
      exportOrOrder: ""
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"]),

    tabIndex: {
      get() {
        if (["export", "order"].includes(this.tab))
          return this.tabs.indexOf("export-order")
        return this.tabs.indexOf(this.tab)
      },

      set() {}
    }
  },

  watch: {
    uid(value) {
      if (!value) return
      this.$router.push({ query: { tab: "overview" } })
      this.fetchOrder()
    },

    $route(value) {
      this.tab = value.query.tab
    }
  },

  methods: {
    ...mapActions(["setSelectedOrder", "setOrderFiles", "setOrderMessages"]),

    redoAnalysis() {
      client.instance.orders.redoAnalysis(this.uid).then(() => {
        this.fetchOrder()
        this.changeTab("library")
      })
    },

    changeTab(tab) {
      if (this.$route.query.tab === tab) return
      this.$router.push({ query: { tab: tab } })
    },

    async fetchOrder() {
      this.loading = true
      this.setSelectedOrder(await client.instance.orders.getOrder(this.uid))
      this.loading = false
    },

    async initialise() {
      this.initialising = true
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      await this.fetchOrder()
      this.initialising = false
    },

    closeOrder() {
      this.setOrderFiles(null)
      this.setOrderMessages(null)
      this.$router.push({ name: "inboxPage" })
    }
  },

  mounted() {
    if (!this.uid && !this.selectedOrder) return
    if (!this.selectedOrder) this.initialise()
    else this.initialising = false
    if (!this.$route.query.tab) {
      this.$router.push({ query: { tab: "overview" } })
    }
    this.tab = this.$route.query.tab
  }
}
</script>
