<template>
  <div>
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font" v-if="!exportOrOrder">
        EXPORT &amp; ORDER
      </v-card-title>
      <v-card-title class="title-font" v-if="exportOrOrder === 'order'">
        <v-icon large class="mr-2">$order</v-icon>
        ORDER FROM OSTEON
      </v-card-title>
      <v-card-title class="title-font" v-if="exportOrOrder === 'export'">
        <v-icon large class="mr-2">$implantexport</v-icon>
        EXPORT ABUTMENT POSITIONS
      </v-card-title>
    </v-card>

    <ChooseExportOrOrder v-if="!exportOrOrder" v-model="exportOrOrder" />

    <ExportComponent v-if="exportOrOrder === 'export'" @cancelled="cancel" />

    <v-row v-if="exportOrOrder === 'order'">
      <v-col>
        <OrderComponent
          @cancelled="cancel"
          @toExport="exportOrOrder = 'export'"
        />
      </v-col>
      <v-col cols="3" v-if="!selectedForwardedOrder">
        <ExistingAndPartialOrders />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ExportComponent from "./ExportOrOrder/ExportComponent.vue"
import OrderComponent from "./ExportOrOrder/OrderComponent.vue"
import ExistingAndPartialOrders from "./ExportOrOrder/ExistingAndPartialOrders.vue"
import ChooseExportOrOrder from "./ExportOrOrder/ChooseExportOrOrder.vue"

export default {
  name: "ExportOrOrder",

  components: {
    ExportComponent,
    OrderComponent,
    ExistingAndPartialOrders,
    ChooseExportOrOrder
  },

  data() {
    return {
      exportOrOrder: ""
    }
  },

  computed: {
    ...mapGetters(["selectedForwardedOrder"])
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder"]),
    cancel() {
      this.exportOrOrder = ""
      this.setSelectedForwardedOrder(null)
    }
  },

  mounted() {
    this.setSelectedForwardedOrder(null)
    if (["export", "order"].includes(this.$route.query.tab))
      this.exportOrOrder = this.$route.query.tab
  }
}
</script>
